import React from 'react';
import PropTypes from 'prop-types';
import of from 'await-of';
import { connect as reduxConnect } from 'react-redux';
import { actions } from '#redux/reducers';
import { WafoForm, WafoFormInput } from '@wafo/forms';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquare, faCheckSquare } from '@fortawesome/free-regular-svg-icons';
import { roAPI } from '#utils/axiosAPI';
import styles from './formRegister.module.css';

const FormRegister = ({ onAfterSubmit, alert }) => {
  const [terminos, setTerminos] = React.useState(false);
  const toggleTerminos = () => setTerminos(prevState => !prevState);

  const handleFormSubmit = async (form, values) => {
    const [, error] = await of(
      roAPI.post('/auth/register', {
        email: values.email,
        password: values.password,
        name: values.name,
        last_name: values.last_name,
      }),
    );
    if (!error) {
      onAfterSubmit('/auth/inicio', 'redirect');
      alert({
        type: 'success',
        title: '¡Estas a un solo paso!',
        message:
          'Antes de iniciar sesión es necesario que verifiques tu correo. Por favor revisa tu bandeja de entrada.',
      });
    }
  };

  return (
    <div data-testid="FormLogin">
      <WafoForm formId="formLogin" locale="es" onSubmit={handleFormSubmit}>
        <WafoFormInput
          type="text"
          name="name"
          label="Nombre"
          placeholder="Nombre"
          customClass="col-12"
          validations={{
            required: true,
          }}
        />

        <WafoFormInput
          type="text"
          name="last_name"
          label="Apellidos"
          placeholder="Apellidos"
          customClass="col-12"
          validations={{
            required: true,
          }}
        />

        <WafoFormInput
          type="email"
          name="email"
          label="Correo electrónico"
          placeholder="Correo electrónico"
          customClass="col-12"
          validations={{
            required: true,
            email: true,
          }}
        />

        <WafoFormInput
          type="password"
          name="password"
          label="Contraseña"
          placeholder="Contraseña"
          customClass="col-12"
          validations={{ required: true }}
        />

        <WafoFormInput
          type="password"
          name="password2"
          label="Confirmar contraseña"
          placeholder="Confirmar contraseña"
          customClass="col-12"
          validations={{
            track: 'password',
            validationFunction: {
              value: value => value.value === value.tracking,
              message: 'La contraseña no coincide.',
            },
          }}
        />

        <div className={`col-12 ${styles['terminos']}`}>
          <a href={process.env.REACT_APP_PRIVACIDAD} target="_blank" rel="noopener noreferrer">
            Leer Aviso de Privacidad.
          </a>
          <button type="button" className="btn btn-link" onClick={toggleTerminos}>
            <FontAwesomeIcon icon={terminos ? faCheckSquare : faSquare} />
            <span>He leído y acepto el Aviso de Privacidad</span>
          </button>
        </div>
      </WafoForm>

      <button type="submit" disabled={!terminos} form="formLogin" className={`btn btn-une ${styles['btn-submit']}`}>
        Registrarse
      </button>
    </div>
  );
};

FormRegister.propTypes = {
  onAfterSubmit: PropTypes.func,
  alert: PropTypes.func,
};

FormRegister.defaultProps = {
  onAfterSubmit: f => f,
  alert: f => f,
};

export default reduxConnect(
  () => ({}),
  dispatch => ({
    alert: alert => dispatch(actions.alert(alert)),
  }),
)(FormRegister);
