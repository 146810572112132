import React from 'react';
import PropTypes from 'prop-types';
import { connect as reduxConnect } from 'react-redux';
import { actions } from '#redux/reducers';
import { WafoForm, WafoFormAutocomplete } from '@wafo/forms';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEraser, faEyeSlash, faTimes } from '@fortawesome/free-solid-svg-icons';
import { roAPI } from '#utils/axiosAPI';
import { LocalTable } from '@wafo/table';
import moment from 'moment';
import 'moment/locale/es';
import styles from './busses.module.css';
import confirmModal from '../../modals/confirm/confirm';

const TableBussesHide = ({ loading }) => {
  const [key, setKey] = React.useState(Math.random());
  const [busses, setBusses] = React.useState([]);
  const [hidden, setHidden] = React.useState([]);

  React.useEffect(() => {
    async function getBusses() {
      try {
        loading.set();
        const busses = await roAPI.get('/vehicles/all');
        if (Array.isArray(busses)) {
          setBusses(busses);
        }
        loading.stop();
      } catch (error) {
        loading.stop();
      }
    }
    getBusses();
  }, [loading]);

  const getData = React.useCallback(async () => {
    try {
      loading.set();
      const resp = await roAPI.get('/vehicles/hiddens');
      setHidden(
        resp.rows.map(row => ({
          vehicle: row.vehicle_code,
          date: row.created_at,
          options: row.vehicle_code,
        })),
      );
      loading.stop();
    } catch (error) {
      loading.stop();
    }
  }, [loading]);

  const handleFormSubmit = async (form, values) => {
    async function doSubmit() {
      try {
        loading.set();
        await roAPI.post('/vehicles/hiddens', {
          vehicle_code: values.bus.code,
        });
        // Reiniciar la forma
        setKey(Math.random());
        // Reaload cochino
        getData();
        loading.stop();
      } catch (error) {
        loading.stop();
        console.error(error);
      }
    }
    if (form.valid) {
      confirmModal({
        message: (
          <p style={{ textAlign: 'center', fontSize: '1.2em', marginBottom: '2rem' }}>
            Esta información se vera reflejada dentro de la aplicación móvil y sitio web.
            <br />
            ¿Desea continuar?
          </p>
        ),
        buttons: [
          {
            label: 'Cancelar',
            class: 'btn-secondary',
          },
          {
            label: 'Aceptar',
            class: 'btn-une',
            onClick: doSubmit,
          },
        ],
      });
    }
  };

  const removeAssign = React.useCallback(
    code => {
      async function doRemove() {
        try {
          loading.set();
          // Deleting
          await roAPI.delete(`/vehicles/hiddens/${code}`);
          // Reload cochino.
          getData();
          loading.stop();
        } catch (error) {
          loading.stop();
        }
      }
      confirmModal({
        message: (
          <p style={{ textAlign: 'center', fontSize: '1.2em', marginBottom: '2rem' }}>
            El autobús sera visible en el mapa.
            <br />
            ¿Desea continuar?
          </p>
        ),
        buttons: [
          {
            label: 'Cancelar',
            class: 'btn-secondary',
          },
          {
            label: 'Aceptar',
            class: 'btn-une',
            onClick: doRemove,
          },
        ],
      });
    },
    [loading, getData],
  );

  const removeAllAssign = React.useCallback(() => {
    async function doRemove() {
      try {
        loading.set();
        // Deleting
        await roAPI.delete('/vehicles/hiddens/purge');
        // Reload cochino.
        getData();
        loading.stop();
      } catch (error) {
        loading.stop();
      }
    }
    confirmModal({
      message: (
        <p style={{ textAlign: 'center', fontSize: '1.2em', marginBottom: '2rem' }}>
          Todos los autobuses ocultos seran visibles en el mapa.
          <br />
          ¿Desea continuar?
        </p>
      ),
      buttons: [
        {
          label: 'Cancelar',
          class: 'btn-secondary',
        },
        {
          label: 'Aceptar',
          class: 'btn-une',
          onClick: doRemove,
        },
      ],
    });
  }, [loading, getData]);

  const columnsConfig = React.useMemo(
    () => ({
      created_at: val => <span>{moment(val).format('DD-MM-YYYY HH:mm')}</span>,
      options: {
        render: function col(val) {
          return (
            <>
              <button type="button" className="btn btn-sm btn-danger" onClick={() => removeAssign(val)}>
                <FontAwesomeIcon icon={faTimes} style={{ marginRight: '.5rem' }} />
                Cancelar
              </button>
            </>
          );
        },
        style: {
          whiteSpace: 'nowrap',
        },
      },
    }),
    [removeAssign],
  );

  return (
    <React.Fragment>
      <div className="row">
        <div className="col-12">
          <h5>Ocultar autobuses</h5>
        </div>
      </div>

      <WafoForm key={key} formId="formBusses" locale="es" onSubmit={handleFormSubmit}>
        <WafoFormAutocomplete
          name="bus"
          customClass="col-3"
          label="Autobús"
          placeholder="Seleccione un autobús"
          items={busses}
          filterItems={(items, query) => items.filter(item => item.code.toLowerCase().indexOf(query.toLowerCase()) !== -1)}
          renderInput={item => `${item.code}`}
          renderItem={item => <p className="autocomplete-item">Unidad: {item.code}</p>}
          validations={{ required: true }}
          extraProps={{
            autoComplete: 'off',
          }}
          handleChange
        />

        <div className={`col-3 ${styles['buttons']}`}>
          <button type="submit" className="btn btn-une">
            <FontAwesomeIcon icon={faEyeSlash} style={{ marginRight: '.5rem' }} />
            <span>Ocultar</span>
          </button>
        </div>
      </WafoForm>

      <div className="row">
        <div className="col-12">
          <p className={styles['desc']}>
            Los autobuses configurados aquí no se mostraran en la aplicación móvil y el mapa web. Seleccione la unidad deseada para realizar
            la configuración.
          </p>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <h5 className={styles['sub-separador']}>Autobuses con configuración</h5>
        </div>
      </div>

      <LocalTable
        locale="es"
        columns={['Código unidad', 'Fecha de configuración', 'Opciones']}
        rows={hidden}
        updateTable={getData}
        columnsConfig={columnsConfig}
        keepPage={false}
        tableClass="table table-striped table-sm"
        tableWrapperClass="table-une"
        controls={
          <React.Fragment>
            <button type="button" className="btn btn-primary" disabled={!hidden.length} onClick={removeAllAssign}>
              <FontAwesomeIcon icon={faEraser} />
            </button>
          </React.Fragment>
        }
      />
    </React.Fragment>
  );
};

TableBussesHide.propTypes = {
  loading: PropTypes.shape({
    set: PropTypes.func,
    stop: PropTypes.func,
  }),
};

TableBussesHide.defaultProps = {
  loading: {
    set: f => f,
    stop: f => f,
  },
};

export default reduxConnect(
  () => ({}),
  dispatch => ({
    loading: {
      set: () => dispatch(actions.loadingSet()),
      stop: () => dispatch(actions.loadingStop()),
    },
  }),
)(TableBussesHide);
