import React from 'react';
import PropTypes from 'prop-types';
import { useLocation, useHistory } from 'react-router-dom';
import InfoColumn from '#components/random/infoColumn/infoColumn';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const Notificacion = ({ cities }) => {
  const {
    state: { notification },
  } = useLocation();

  const history = useHistory();

  const splitTopic = React.useCallback(topic => {
    const tipo = topic.split('_')[2];
    switch (tipo) {
      case 'general':
        return 'Aviso general';
      case 'importante':
        return 'Aviso importante';
      case 'linea':
        return 'Aviso de línea';
      case 'encuesta':
        return 'Encuesta general';
      case 'encuesta_registrados':
        return 'Encuesta registrados';
      case 'prueba':
        return 'Prueba';
      default:
        return 'Sin tipo';
    }
  }, []);

  const tipo = React.useMemo(() => {
    if (notification.topic !== 'custom_topics') {
      return splitTopic(notification.topic);
    } else {
      return splitTopic(notification.metadata.topics[0]);
    }
  }, [notification, splitTopic]);

  const ciudad = React.useMemo(() => {
    if (notification.topic !== 'custom_topics') {
      const id = notification.topic.split('_')[1];
      const city = cities.find(c => c.id_city === id);
      if (city) {
        return city.name;
      }
    } else {
      const ids = notification.metadata.topics.map(topic => {
        const id = topic.split('_')[1];
        const city = cities.find(c => c.id_city === id);
        if (city) {
          return city.name;
        }
        return '';
      });
      return ids.join(', ');
    }
  }, [notification, cities]);

  return (
    <>
      <div className="row">
        <div className="col-12">
          <button type="button" className={`btn btn-link back`} onClick={() => history.goBack()}>
            <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: '.5rem' }} />
            <span>Regresar</span>
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-12"></div>
        <InfoColumn title="ID de notificación" info={`#${notification.id_notification}`} />
        <InfoColumn title="Tipo de notificación" info={tipo} />
        <InfoColumn title="Ciudad notificada" info={ciudad} />
        <InfoColumn
          title="Estado de envío"
          info={
            <span
              className={`badge ${notification.is_sent ? 'badge-success' : 'badge-warning'}`}
              style={{ padding: '.25rem 1rem' }}
            >
              {notification.is_sent ? 'Enviada' : 'Pendiente'}
            </span>
          }
        />
        <InfoColumn
          title="Fecha de envío"
          info={
            <React.Fragment>
              {!notification.send_date && <span>{moment(notification.created_at).format('DD MMMM YYYY HH:mm')}</span>}
              {notification.send_date && (
                <React.Fragment>
                  <span title="Hora de envío">{moment(notification.send_date).format('DD MMMM YYYY HH:mm')}</span>
                  <br />
                  <span title="Hora de creación" style={{ fontSize: '0.8em' }}>
                    {moment(notification.created_at).format('DD MMMM YYYY HH:mm')}
                  </span>
                </React.Fragment>
              )}
            </React.Fragment>
          }
        />
        <InfoColumn title="Autor" info={`${notification.user.name} ${notification.user.last_name}`} />
        <div className="col-12" style={{ marginTop: '1rem' }}>
          <h5>{notification.title}</h5>
          <p>{notification.message}</p>
        </div>
      </div>
    </>
  );
};

Notificacion.propTypes = {
  cities: PropTypes.array,
};

export default Notificacion;
