import React from 'react';
import of from 'await-of';
import { DataTable } from '@wafo/table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faKey, faToggleOn, faToggleOff, faIdCard, faList } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import 'moment/locale/es';
import { roAPI } from '#utils/axiosAPI';
import confirmModal from '#components/modals/confirm/confirm';
import useLoading from '#hooks/redux/useLoading';
import { Link } from 'react-router-dom';

const TableUsers = () => {
  const [rows, setRows] = React.useState([]);
  const [totalRows, setTotalRows] = React.useState(0);

  const loading = useLoading();

  const getData = React.useCallback(
    async event => {
      const { size, page, search } = event;
      loading.set();
      const [resp, error] = await of(
        roAPI.get('/admin/users', {
          params: {
            limit: size,
            page,
            nocache: true,
            ...(search && { search }),
            order: '-id_user',
          },
        }),
      );
      if (!error) {
        const rows = resp.rows.map(row => ({
          id: row.id_user,
          type: row.type,
          // card: row.user_cards.length ? row.user_cards[0] : null,
          card: { cards: row.user_cards, curp: row.curp },
          social: {
            fb: row.facebook_id,
            go: row.google_id,
          },
          name: `${row.name} ${row.last_name}`,
          contact: { email: row.email, phone: row.phone_number },
          date: row.created_at,
          options: {
            id: row.id_user,
            disabled: row.disabled,
            user: row,
          },
        }));
        setRows(rows);
        setTotalRows(parseInt(resp.count, 10));
      }
      loading.stop();
    },
    [loading],
  );

  const clickReload = (id = 'wafotable-reload') => {
    const btn = document.getElementById(id);
    btn.click();
  };

  const handleDisable = React.useCallback(
    (id, val) => {
      async function disableUser() {
        loading.set();
        const [, err] = await of(
          roAPI.put(`/admin/users/${id}`, {
            disabled: !val,
          }),
        );
        if (!err) {
          clickReload();
        }
        loading.stop();
      }
      confirmModal({
        message: (
          <p style={{ textAlign: 'center', fontSize: '1.2em', marginBottom: '2rem' }}>
            {!val ? 'El usuario perdera el acceso al sistema de transporte.' : 'El usuario recuperara el acceso al sistema de transporte.'}
            <br />
            ¿Desea continuar?
          </p>
        ),
        buttons: [
          {
            label: 'Cancelar',
            class: 'btn-secondary',
          },
          {
            label: 'Aceptar',
            class: 'btn-une',
            onClick: disableUser,
          },
        ],
      });
    },
    [loading],
  );

  const columnsConfig = React.useMemo(
    () => ({
      id: {
        style: {
          verticalAlign: 'middle',
        },
      },
      type: {
        render: function col(val) {
          return (
            <span className="badge badge-secondary" style={{ padding: '.5em 1.5em' }}>
              <FontAwesomeIcon icon={val === 1 ? faKey : faUser} />
            </span>
          );
        },
        style: {
          verticalAlign: 'middle',
        },
      },
      card: {
        render: function col(val) {
          const card = val.cards.length ? val.cards[0] : null;
          return (
            <span className={`badge ${card || val.curp ? 'badge-success' : 'badge-secondary'}`} style={{ padding: '.5em 1em' }}>
              <FontAwesomeIcon icon={faIdCard} />
            </span>
          );
        },
        style: {
          verticalAlign: 'middle',
        },
      },
      social: {
        render: function col(val) {
          return (
            <>
              <span
                className={`badge ${val.fb ? 'badge-primary' : 'badge-secondary'}`}
                style={{ padding: '.5em 1em', marginRight: '.5rem' }}
              >
                F
              </span>
              <span className={`badge ${val.go ? 'badge-light' : 'badge-secondary'}`} style={{ padding: '.5em 1em', marginRight: '.5rem' }}>
                G
              </span>
            </>
          );
        },
        style: {
          verticalAlign: 'middle',
          whiteSpace: 'nowrap',
        },
      },
      name: {
        style: {
          verticalAlign: 'middle',
        },
      },
      contact: {
        render: function col(val) {
          return (
            <>
              {val.email && (
                <>
                  <span>{val.email}</span>
                  <br />
                </>
              )}
              {val.phone && <span>{val.phone}</span>}
              {!val.email && !val.phone && <span>Sin información</span>}
            </>
          );
        },
        style: {
          fontSize: '0.9em',
          verticalAlign: 'middle',
        },
      },
      date: {
        render: function col(val) {
          return <span> {moment(val).format('DD MMMM YYYY HH:mm')}</span>;
        },
        style: {
          verticalAlign: 'middle',
        },
      },
      options: {
        render: function col(val) {
          return (
            <>
              <button
                type="button"
                className={`btn btn-sm ${!val.disabled ? 'btn-success' : 'btn-danger'}`}
                onClick={() => handleDisable(val.id, val.disabled)}
                style={{ padding: '.25rem 1rem' }}
              >
                <FontAwesomeIcon icon={!val.disabled ? faToggleOn : faToggleOff} />
              </button>
              <Link className="btn btn-sm btn-secondary" to={{ pathname: `/panel/users/user/${val.id}`, state: { user: val.user } }}>
                <FontAwesomeIcon icon={faList} style={{ marginRight: '.5rem' }} />
                <span>Detalles</span>
              </Link>
            </>
          );
        },
        style: {
          whiteSpace: 'nowrap',
          verticalAlign: 'middle',
        },
      },
    }),
    [handleDisable],
  );

  return (
    <div>
      <DataTable
        locale="es"
        columns={['ID', 'Tipo', '', '', 'Nombre', 'Contacto', 'Registro', 'Opciones']}
        rows={rows}
        totalRows={totalRows}
        onPagination={getData}
        columnsConfig={columnsConfig}
        tableClass="table table-striped table-sm"
        tableWrapperClass="table-une"
        noRowsMessage="Sin puntos de interés"
      />
    </div>
  );
};

TableUsers.propTypes = {};

TableUsers.defaultProps = {};

export default TableUsers;
