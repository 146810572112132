import React from 'react';
import PropTypes from 'prop-types';
import { connect as reduxConnect } from 'react-redux';
import { actions } from '#redux/reducers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { WafoForm, WafoFormInput, WafoFormSelect } from '@wafo/forms';
import { useHistory, useParams } from 'react-router-dom';
import ColorPicker from '#components/forms/customForms/colorPicker/colorPicker';
import ImagePicker from '#components/forms/customForms/imagePicker/imagePicker';
import { roAPI } from '#utils/axiosAPI';
import useUpdateCities from '#hooks/useUpdateCities';
import { information_types } from '#utils/utils';
import WafoDraftJS from '#components/forms/customForms/wafoDraftJS/wafoDraftJS';
import { draftToMarkdown, markdownToDraft } from 'markdown-draft-js';
import { convertToRaw, convertFromRaw, EditorState } from 'draft-js';

const FormCredentialsInformation = ({ loading, alert, cities, updateCities, location }) => {
  const [information] = React.useState(() => {
    if (location.state && location.state.information) {
      const rawData = markdownToDraft(location.state.information.description);
      const contentState = convertFromRaw(rawData);
      const newEditorState = EditorState.createWithContent(contentState);

      return {
        ...location.state.information,
        description: newEditorState,
      };
    }
    return {};
  });

  const history = useHistory();
  const { id: editing_id } = useParams();
  useUpdateCities(updateCities, loading, false);

  const handleFormSubmit = async (form, values) => {
    if (form.valid) {
      try {
        loading.set();
        // JS to markdown
        const rawContentState = convertToRaw(values.description.getCurrentContent());
        const markdown = draftToMarkdown(rawContentState);
        // Creating form model
        const formModel = new FormData();
        formModel.append('id_city', values.id_city);
        if (!editing_id) {
          formModel.append('type', values.type);
        }
        formModel.append('title', values.title);
        formModel.append('bar_color', values.bar_color);
        formModel.append('text_color', values.text_color);
        formModel.append('order', values.order);
        formModel.append('description', markdown);
        if (typeof values.image_icon !== 'string' && values.image_icon) {
          formModel.append('image_icon', values.image_icon);
        }

        editing_id
          ? await roAPI.put(`/credentials/instructions/${editing_id}`, formModel)
          : await roAPI.post('/credentials/instructions', formModel);

        history.push('/panel/credencializacion/informacion');
        alert({
          type: 'success',
          title: '¡Información añadida!',
          message: 'Se ha añadido exitosamente la información de credencialización.',
          timer: 3000,
        });
        loading.stop();
      } catch (error) {
        loading.stop();
        console.error(error);
      }
    }
  };

  return (
    <div className="row">
      <div className="col-12">
        <button type="button" className={`btn btn-link back`} onClick={() => history.goBack()}>
          <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: '.5rem' }} />
          <span>Regresar</span>
        </button>
      </div>

      <div className="col-12">
        <WafoForm formId="formInformation" locale="es" onSubmit={handleFormSubmit} values={information}>
          <WafoFormSelect
            name="id_city"
            customClass="col-12 col-md-4"
            label="Ciudad"
            defaultValue="Selecciona ciudad"
            options={cities.map(x => ({
              value: x.id_city,
              display: x.name,
            }))}
            validations={{ required: true }}
          />

          <WafoFormSelect
            name="type"
            customClass="col-12 col-md-4"
            label="Tipo de información"
            defaultValue="Selecciona tipo"
            options={information_types.map(it => ({
              value: it.id,
              display: it.name,
            }))}
            validations={{ required: true }}
            extraProps={{
              disabled: editing_id,
            }}
          />

          <WafoFormInput
            type="text"
            name="title"
            label="Título"
            placeholder="Inserte título"
            customClass="col-12 col-md-4"
            validations={{ required: true }}
          />

          <WafoFormInput
            type="number"
            name="order"
            label="Prioridad"
            placeholder="0"
            customClass="col-12 col-md-4"
            validations={{ required: true }}
            extraProps={{
              min: 1,
              max: 10,
            }}
          >
            <span style={{ fontSize: '0.8em', color: '#777', marginBottom: 0 }}>
              El orden de prioridad indica que se mostrara en la aplicación. Mientras menor el número, mayor prioridad.
            </span>
          </WafoFormInput>

          <ColorPicker
            name="bar_color"
            label="Color de barra"
            customClass="col-12 col-md-4"
            validations={{ required: true }}
            handleChange
          />

          <ColorPicker
            name="text_color"
            label="Color de texto"
            customClass="col-12 col-md-4"
            validations={{ required: true }}
            handleChange
          />

          <div className="col-12 col-md-4" style={{ marginBottom: '1rem' }}>
            <div className="row">
              <ImagePicker
                name="image_icon"
                label="Icono de información"
                customClass="col-12 no-margin"
                serverBasePath={process.env.REACT_APP_IMG_CREDENTIALS}
                help="El icono debe tener un tamaño de 160px x 160px (o un aspecto similar), 96ppp, formato PNG y de poco peso (50KB)."
                validations={{ required: true }}
                handleChange
              />
              <div className="col-12">
                <button type="button" className="btn btn-link no-padding" onClick={f => f}>
                  Descargar ejemplo de icono
                </button>
              </div>
            </div>
          </div>

          <WafoDraftJS
            name="description"
            label="Contenido"
            customClass="col-12"
            validations={{ required: false }}
            handleChange
          />
        </WafoForm>
        <button type="submit" form="formInformation" className={`btn btn-une btn-submit btn-submit-center`}>
          Guardar cambios
        </button>
      </div>
    </div>
  );
};

FormCredentialsInformation.propTypes = {
  loading: PropTypes.shape({
    set: PropTypes.func,
    stop: PropTypes.func,
  }),
  alert: PropTypes.func,
  cities: PropTypes.array,
  updateCities: PropTypes.func,
  location: PropTypes.shape({
    state: PropTypes.any,
  }),
};

FormCredentialsInformation.defaultProps = {
  loading: {
    set: f => f,
    stop: f => f,
  },
  alert: f => f,
  cities: [],
  updateCities: f => f,
  location: {
    state: {},
  },
};

export default reduxConnect(
  state => ({
    cities: state.cities,
  }),
  dispatch => ({
    updateCities: cities => dispatch(actions.citiesUpdate(cities)),
    loading: {
      set: () => dispatch(actions.loadingSet()),
      stop: () => dispatch(actions.loadingStop()),
    },
    alert: alert => dispatch(actions.alert(alert)),
  }),
)(FormCredentialsInformation);
