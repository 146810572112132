import React from 'react';
import PropTypes from 'prop-types';
import { WafoForm, WafoFormInput, WafoFormSelect, WafoFormTextArea } from '@wafo/forms';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { roAPI } from '#utils/axiosAPI';
import moment from 'moment';
import MultiSelect from '#components/forms/customForms/multiSelect/multiSelect';
import DatetimePicker from '#components/forms/customForms/datetimePicker/datetimePicker';

const FormNotification = ({ history, cities }) => {
  const handleFormSubmit = async (form, values) => {
    if (!form.valid) {
      return;
    }
    // avisos_ciudad_importancia
    try {
      const send_date = moment(values.send_date, 'YYYY-MM-DD:HH:mm').toISOString();
      const level = values.level === 'linea' ? 'importante' : values.level;
      const topic = values.city.map(item => `avisos_${item.id_city}_${level}`).join(',');
      await roAPI.post('/notifications', {
        title: values.title,
        message: values.message,
        topic,
        ...(send_date && { send_date }),
      });
      history.push('/panel/notificaciones');
    } catch (error) {
      //
    }
  };

  const topics = React.useMemo(() => {
    // eslint-disable-next-line
    const allowed = process.env.REACT_APP_PUSH == 'true';
    if (allowed) {
      return [
        { display: 'Aviso general', value: 'general' },
        { display: 'Aviso importante', value: 'importante' },
        { display: 'Aviso de línea', value: 'linea' },
        { display: 'Encuesta general', value: 'encuesta' },
        { display: 'Encuesta registrados', value: 'encuesta_registrados' },
        { display: 'Pruebas', value: 'prueba' },
      ];
    } else {
      return [{ display: 'Pruebas', value: 'prueba' }];
    }
  }, []);

  return (
    <div data-testid="FormNotification">
      <div className="row">
        <div className="col-12">
          <button type="button" className={`btn btn-link back`} onClick={() => history.goBack()}>
            <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: '.5rem' }} />
            <span>Regresar</span>
          </button>
        </div>
      </div>
      <p>Registra una nueva notificación que será enviada a todos los dispositivos que cuenten con la aplicación.</p>

      <WafoForm formId="formNotification" locale="es" onSubmit={handleFormSubmit}>
        <WafoFormInput
          type="text"
          name="title"
          label="Título"
          placeholder="Inserte título"
          customClass="col-12 col-md-4"
          validations={{ required: true, maxLength: 255 }}
        />

        <MultiSelect
          name="city"
          customClass="col-md-4"
          label="Ciudad"
          placeholder="Seleccione ciudad"
          itemKey="id_city"
          items={cities}
          renderItem={item => item.name}
          renderInput={item => item.name}
          handleChange
          validations={{ required: true }}
        />

        <WafoFormSelect
          name="level"
          label="Tipo de notificación"
          defaultValue="Seleccione el tipo"
          customClass="col-12 col-md-4"
          options={topics}
          validations={{ required: true }}
        />

        <WafoFormTextArea
          name="message"
          label="Mensaje"
          placeholder="Inserte su mensaje"
          customClass="col-12"
          validations={{ required: true, maxLength: 1024 }}
        />

        <div className="col-12">
          <p className="form-separator">Programación (Opcional)</p>
        </div>

        <DatetimePicker name="send_date" label="Fecha y hora de envío" customClass="col-4" handleChange />
      </WafoForm>

      <button type="submit" form="formNotification" className={`btn btn-une btn-submit btn-submit-center`}>
        Publicar notificación
      </button>
    </div>
  );
};

FormNotification.propTypes = {
  history: PropTypes.any,
  cities: PropTypes.array,
};

FormNotification.defaultProps = {
  history: {},
  cities: [],
};

export default FormNotification;
