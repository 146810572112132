import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { getVerifiedToken } from '#utils/authService';

const PrivateRoute = ({ component: Component, allowed, ...rest }) => {
  const [access, setAccess] = React.useState(undefined);

  React.useEffect(() => {
    async function checkToken() {
      const verified = await getVerifiedToken();
      if (verified) {
        setAccess(verified.decoded);
      } else {
        setAccess(null);
      }
    }
    checkToken();
  }, []);

  if (access === undefined) {
    return <></>;
  } else if (access === null || (access && !allowed.includes(access.type))) {
    return <Redirect to="/auth/inicio" />;
  } else if (access && allowed.includes(access.type)) {
    return <Route {...rest} component={Component} />;
  }
};

export default PrivateRoute;
