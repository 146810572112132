import React from 'react';
import PropTypes from 'prop-types';
import { DataTable } from '@wafo/table';
import { connect as reduxConnect } from 'react-redux';
import { actions } from '#redux/reducers';
import { roAPI } from '#utils/axiosAPI';
import useUpdateCities from '#hooks/useUpdateCities';
import { information_types } from '#utils/utils';
import { WafoFormSelect } from '@wafo/forms';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import confirmModal from '#components/modals/confirm/confirm';

const TableCredentialsInformation = ({ loading, cities, updateCities }) => {
  const [rows, setRows] = React.useState([]);
  const [totalRows, setTotalRows] = React.useState(0);
  // Filters
  const [ciudad, setCiudad] = React.useState('');

  useUpdateCities(updateCities, loading, false);

  const tableRef = React.useRef();

  const getData = React.useCallback(
    async ({ size, page, search }) => {
      try {
        loading.set();
        const resp = await roAPI.get('/credentials/instructions', {
          params: {
            limit: size,
            page,
            ...(search && { search }),
            ...(ciudad && { id_city: ciudad }),
          },
        });

        setRows(
          resp.rows.map(row => ({
            id: row.id_ci,
            city: row.id_city,
            type: row.type,
            title: row.title,
            order: row.order,
            date: row.updated_at,
            options: row,
          })),
        );
        setTotalRows(resp.count);
        loading.stop();
      } catch (error) {
        loading.stop();
        console.error(error);
      }
    },
    [loading, ciudad],
  );

  const handleDelete = React.useCallback(
    id => {
      async function deleteInformation() {
        try {
          loading.set();
          await roAPI.delete(`/credentials/instructions/${id}`);
          tableRef.current.forceUpdate();
          loading.stop();
        } catch (error) {
          loading.stop();
        }
      }
      confirmModal({
        message: (
          <p style={{ textAlign: 'center', fontSize: '1.2em', marginBottom: '2rem' }}>
            Será elimanada y no se mostrara dentro de la aplicación.
            <br />
            ¿Desea continuar?
          </p>
        ),
        buttons: [
          {
            label: 'Cancelar',
            class: 'btn-secondary',
          },
          {
            label: 'Aceptar',
            class: 'btn-une',
            onClick: deleteInformation,
          },
        ],
      });
    },
    [loading],
  );

  const columnsConfig = React.useMemo(
    () => ({
      id: val => <span>#{val}</span>,
      city: val => {
        const city = cities.find(c => c.id_city === val);
        return <span>{city && city.name}</span>;
      },
      type: val => {
        const type = information_types.find(t => t.id === val);
        return <span>{type && type.name}</span>;
      },
      date: val => <span>{moment(val).format('DD MMMM YYYY')}</span>,
      options: {
        render: val => (
          <>
            <Link
              className="btn btn-sm btn-primary"
              to={{ pathname: `/panel/credencializacion/informacion/nueva/${val.id_ci}`, state: { information: val } }}
              style={{ marginRight: '.25rem' }}
            >
              <FontAwesomeIcon icon={faEdit} style={{ marginRight: '.5rem' }} />
              <span>Editar</span>
            </Link>
            <button type="button" className="btn btn-sm btn-danger" onClick={() => handleDelete(val.id_ci)}>
              <FontAwesomeIcon icon={faTrashAlt} style={{ marginRight: '.5rem' }} />
              Eliminar
            </button>
          </>
        ),
        style: {
          whiteSpace: 'nowrap',
        },
      },
    }),
    [cities, handleDelete],
  );

  return (
    <div>
      <DataTable
        ref={tableRef}
        locale="es"
        columns={['ID', 'Ciudad', 'Tipo', 'Título', 'Orden', 'Fecha', 'Opciones']}
        rows={rows}
        totalRows={totalRows}
        onPagination={getData}
        columnsConfig={columnsConfig}
        tableClass="table table-striped table-sm"
        tableWrapperClass="table-une"
        noRowsMessage="Sin información de credencialización para mostrar"
        controls={
          <div className="row" style={{ width: '100%' }}>
            <WafoFormSelect
              name="city"
              customClass="col-12 col-md-4"
              defaultValue="Filtre por ciudad"
              value={ciudad}
              onChangeCallback={({ target: { value } }) => setCiudad(value)}
              options={cities.map(x => ({
                value: x.id_city,
                display: x.name,
              }))}
            />
          </div>
        }
      />
    </div>
  );
};

TableCredentialsInformation.propTypes = {
  loading: PropTypes.shape({
    set: PropTypes.func,
    stop: PropTypes.func,
  }),
  cities: PropTypes.array,
  updateCities: PropTypes.func,
};

TableCredentialsInformation.defaultProps = {
  loading: {
    set: f => f,
    stop: f => f,
  },
  cities: [],
  updateCities: f => f,
};

export default reduxConnect(
  state => ({
    cities: state.cities,
  }),
  dispatch => ({
    updateCities: cities => dispatch(actions.citiesUpdate(cities)),
    loading: {
      set: () => dispatch(actions.loadingSet()),
      stop: () => dispatch(actions.loadingStop()),
    },
  }),
)(TableCredentialsInformation);
