import React from 'react';
import PropTypes from 'prop-types';
import { distanceInWordsToNow } from 'date-fns';
import esLocale from 'date-fns/locale/es';

const FechaEnTexto = ({ fecha }) => {
  const now = new Date();
  const fechaCorregida = new Date(fecha);
  fechaCorregida.setFullYear(now.getFullYear());
  fechaCorregida.setMonth(now.getMonth());
  fechaCorregida.setDate(now.getDate());
  const fechaTexto = distanceInWordsToNow(fechaCorregida, { locale: esLocale })
  return <span>{fechaTexto}</span>;
};

FechaEnTexto.propTypes = {
  fecha: PropTypes.any,
};

FechaEnTexto.defaultProps = {
  fecha: new Date(),
};

export default FechaEnTexto;
