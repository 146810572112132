import React from 'react';
import PropTypes from 'prop-types';
import { Map, TileLayer, Polyline, Marker } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { decode } from '@mapbox/polyline';
import { boundsOfCity } from '#utils/utils';
import { generateMarker } from '#components/maps/extras/markers/markers';
import styles from './stopsMap.module.css';

const busStopIcon = generateMarker({ icon: 'bus_stop' });
const busStopSIcon = generateMarker({ icon: 'bus_stop_selected' });
const busStopCIcon = generateMarker({ icon: 'bus_stop_collision' });
const busStopGIcon = generateMarker({ icon: 'bus_stop_gray' });
const busStopVIcon = generateMarker({ icon: 'bus_stop_green' });

const StopsMap = ({ cities, city, route, stops, selectedStop, onStopClick, onStopDrag, onMapClick }) => {
  const [map, setMap] = React.useState(() => {
    const { center, bounds } = boundsOfCity(cities, city);
    return {
      zoom: 12,
      position: center,
      bounds,
    };
  });

  React.useEffect(() => {
    const { center, bounds } = boundsOfCity(cities, city);
    setMap({
      position: center,
      zoom: 12,
      bounds,
    });
  }, [city, cities]);

  const mapRef = React.useRef(null);
  const polylineRef = React.useRef(null);

  const resetBounds = React.useCallback(() => {
    if (route.group_id && polylineRef.current) {
      // reset bounds to route
      mapRef.current.leafletElement.flyToBounds(polylineRef.current.leafletElement.getBounds());
    } else if (cities.length) {
      // reset bounds to city
      mapRef.current.leafletElement.flyToBounds(boundsOfCity(cities, city).bounds);
      // mapRef.current.leafletElement.flyTo(citiesCoords[city], 12);
    }
  }, [cities, city, route]);

  React.useEffect(() => {
    resetBounds();
  }, [route, resetBounds]);

  const memoizedDecodedRoute = React.useMemo(() => {
    let decodedRoute = decode(route.polyline || '');
    if (decodedRoute) {
      // decodedRoute = decodedRoute.map(cords => [cords[1], cords[0]]);
      return decodedRoute;
    }
    return [];
  }, [route]);

  const stopsOpacity = selectedStop && selectedStop.new ? 0.5 : 1;

  return (
    <div className={styles['map-wrapper']}>
      <Map
        ref={mapRef}
        center={map.position}
        zoom={map.zoom}
        maxZoom={18}
        animate
        bounds={map.bounds}
        className={styles['leaflet-container']}
        onClick={onMapClick}
      >
        <TileLayer
          attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a>'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {memoizedDecodedRoute.length !== 0 && (
          <Polyline ref={polylineRef} positions={memoizedDecodedRoute} weight={4} />
        )}
        {stops.length !== 0 &&
          stops.map(stop => {
            let icon = busStopIcon;
            if (stop.codigo === selectedStop.codigo) {
              icon = busStopSIcon;
            } else if (stop.collisions && stop.collisions.length > 0) {
              icon = busStopCIcon;
            }
            const opacity = selectedStop && selectedStop.afterStop === stop.codigo ? 1 : stopsOpacity;
            return (
              <Marker
                key={stop.codigo}
                position={[stop.latitude, stop.longitude]}
                title={stop.name}
                icon={icon}
                onClick={event => onStopClick(event, stop)}
                onDragEnd={event => onStopDrag(event, stop)}
                draggable={!selectedStop.new}
                zIndexOffset={100}
                opacity={opacity}
              />
            );
          })}
        {selectedStop &&
          selectedStop.collisions &&
          selectedStop.collisions.map(stop => {
            const icon = stop.replace ? busStopVIcon : busStopGIcon;
            return (
              <Marker
                key={stop.codigo}
                position={[stop.latitude, stop.longitude]}
                title={stop.name}
                icon={icon}
                opacity={0.75}
              />
            );
          })}
        {selectedStop && selectedStop.new && (
          <Marker
            position={[selectedStop.latitude, selectedStop.longitude]}
            title={selectedStop.name}
            icon={busStopVIcon}
            onDragEnd={event => onStopDrag(event, selectedStop)}
            draggable={true}
            zIndexOffset={105}
          />
        )}
      </Map>
    </div>
  );
};

StopsMap.propTypes = {
  cities: PropTypes.array,
  city: PropTypes.string,
  route: PropTypes.any,
  stops: PropTypes.array,
  selectedStop: PropTypes.any,
  onStopClick: PropTypes.func,
  onStopDrag: PropTypes.func,
  onMapClick: PropTypes.func,
};

StopsMap.defaultProps = {
  cities: [],
  city: '1',
  route: {},
  stops: [],
  selectedStop: {},
  onStopClick: f => f,
  onStopDrag: f => f,
  onMapClick: f => f,
};

export default StopsMap;
