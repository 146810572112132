import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styles from './cityCard.module.css';

const CityCard = ({ name, bgColor, imgSrc, path, onCardClick }) => (
  <Link
    data-testid="cityCard"
    style={{ backgroundColor: bgColor }}
    className={`mirai-shadow ${styles['card']}`}
    to={path}
    onClick={onCardClick}
  >
    <img src={imgSrc} alt="City Icon" className={styles['icon']} />
    <span className={styles['name']}>{name}</span>
  </Link>
);

CityCard.propTypes = {
  name: PropTypes.string,
  bgColor: PropTypes.string,
  imgSrc: PropTypes.any,
  path: PropTypes.string,
  onCardClick: PropTypes.func,
};

CityCard.defaultProps = {
  name: '',
  bgColor: '#fff',
  imgSrc: undefined,
  path: '/rutas',
  onCardClick: f => f,
};

export default CityCard;
