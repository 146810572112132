import uuidv4 from 'uuid/v4';
import errors from '#utils/customErrors.json';

/** ***************************** */
/** City */
/** ***************************** */

export const city = (state = null, action) => {
  switch (action.type) {
    case 'CITY_CHANGE':
      return action.payload;
    default:
      return state;
  }
};

const changeCity = newCity => ({
  type: 'CITY_CHANGE',
  payload: newCity,
});

/** ***************************** */
/** Cities */
/** ***************************** */

export const cities = (state = [], action) => {
  switch (action.type) {
    case 'CITIES_UPDATE':
      return action.payload;
    default:
      return state;
  }
};

const citiesUpdate = cities => ({
  type: 'CITIES_UPDATE',
  payload: cities,
});

/** ***************************** */
/** Routes */
/** ***************************** */

export const routes = (state = [], action) => {
  switch (action.type) {
    case 'UPDATE_ROUTES':
      return action.payload;
    default:
      return state;
  }
};

const updateRoutes = (routes, filter = true) => {
  let filtered = routes;
  if (filter) {
    filtered = routes.filter(x => x.internal_order && !x.disabled).sort((a, b) => a.internal_order - b.internal_order);
  }
  return {
    type: 'UPDATE_ROUTES',
    payload: filtered,
  };
};

/** ***************************** */
/** Panel sidebar */
/** ***************************** */

export const panelsidebarToggle = (state = false, action) => {
  switch (action.type) {
    case 'PANELSIDEBAR_TOGGLE':
      return action.payload;
    default:
      return state;
  }
};

const togglePanelsidebar = sidebar => ({
  type: 'PANELSIDEBAR_TOGGLE',
  payload: sidebar,
});

/** ***************************** */
/** Alerts */
/** ***************************** */

export const alerts = (state = [], action) => {
  switch (action.type) {
    case 'ALERT_ADD':
      return [...state, action.payload];
    case 'ALERT_REMOVE': {
      const index = state.findIndex(x => x.uuid === action.payload);
      return [...state.slice(0, index), ...state.slice(index + 1)];
    }
    default:
      return state;
  }
};

export const alertDefault = {
  uuid: 'abc123',
  type: 'dark',
  title: 'Alerta',
  message: 'Mensaje de alerta',
  timer: 0,
};

const alert = alert => ({
  type: 'ALERT_ADD',
  payload: {
    ...alertDefault,
    ...alert,
    uuid: uuidv4(),
  },
});

const alertErrorByType = (type, title = 'Error', timer = 0) => {
  return {
    type: 'ALERT_ADD',
    payload: {
      ...alertDefault,
      uuid: uuidv4(),
      type: 'danger',
      title,
      message: errors[type] || errors['default'],
      timer,
    },
  };
};

const alertRemove = uuid => ({
  type: 'ALERT_REMOVE',
  payload: uuid,
});

/** ***************************** */
/** Loading */
/** ***************************** */

export const loading = (state = [], action) => {
  switch (action.type) {
    case 'LOADING_ADD':
      return [...state, true];
    case 'LOADING_REMOVE': {
      if (state.length > 0) {
        const dummy = [...state];
        dummy.pop();
        return dummy;
      }
      return state;
    }
    default:
      return state;
  }
};

const loadingSet = () => ({ type: 'LOADING_ADD' });

const loadingStop = () => ({ type: 'LOADING_REMOVE' });

/** ***************************** */
/** Terminos y condiciones Ayylmao */
/** ***************************** */

export const terms = (state = false, action) => {
  switch (action.type) {
    case 'TERMS_ACCEPT':
      return action.payload;
    default:
      return state;
  }
};

const acceptTerms = accept => ({
  type: 'TERMS_ACCEPT',
  payload: accept,
});

/** ***************************** */
//#region viaje
/** ***************************** */

const defaultTrip = {
  origin: null,
  destination: null,
  routes: [],
  selectedTrip: 0,
};

export const trip = (state = defaultTrip, action) => {
  switch (action.type) {
    case 'TRIP_ORIGIN_UPDATE':
      return {
        ...state,
        origin: action.payload,
      };
    case 'TRIP_DESTINATION_UPDATE':
      return {
        ...state,
        destination: action.payload,
      };
    case 'TRIP_ROUTES_UPDATE':
      return {
        ...state,
        routes: action.payload,
      };
    case 'TRIP_SELECTEDTRIP_UPDATE':
      return {
        ...state,
        selectedTrip: action.payload,
      };
    case 'TRIP_CLEARTRIP':
      return action.payload;
    default:
      return state;
  }
};

const tripUpdateOrigin = origin => ({
  type: 'TRIP_ORIGIN_UPDATE',
  payload: origin,
});

const tripUpdateDestination = origin => ({
  type: 'TRIP_DESTINATION_UPDATE',
  payload: origin,
});

const tripUpdateRoutes = routes => ({
  type: 'TRIP_ROUTES_UPDATE',
  payload: routes,
});

const tripUpdateSelectedTrip = selected => ({
  type: 'TRIP_SELECTEDTRIP_UPDATE',
  payload: selected,
});

const tripClearTrip = () => ({
  type: 'TRIP_CLEARTRIP',
  payload: defaultTrip,
});

//#endregion

/** ***************************** */
/** Actions */
/** ***************************** */

export const actions = {
  citiesUpdate,
  changeCity,
  updateRoutes,
  togglePanelsidebar,
  alert,
  alertRemove,
  alertErrorByType,
  loadingSet,
  loadingStop,
  acceptTerms,
  tripUpdateOrigin,
  tripUpdateDestination,
  tripUpdateRoutes,
  tripUpdateSelectedTrip,
  tripClearTrip,
};
