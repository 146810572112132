import React from 'react';
import PropTypes from 'prop-types';
import { WafoForm, WafoFormInput } from '@wafo/forms';
import styles from './formUserProfile.module.css';
import { roAPI } from '#utils/axiosAPI';

const FormUserProfile = ({ currentUser, afterSubmit }) => {
  const handleFormSubmit = async (form, values) => {
    try {
      if (!form.valid) { return; }
      await roAPI.put('/user', {
        name: values.name,
        last_name: values.last_name,
        ...(values.phone_number && ({ phone_number: values.phone_number })),
        ...(values.password && ({ password: values.password })),
      });
      if (values.email && currentUser.email !== values.email) {
        await roAPI.put('/user/email', {
          email: values.email,
        });
      }
      afterSubmit();
    } catch (error) {
      //
    }
  }

  return (
    <div data-testid="FormUserProfile">
      <WafoForm formId="formUser" locale="es" onSubmit={handleFormSubmit} values={currentUser}>
        <WafoFormInput
          type="text"
          name="name"
          label="Nombre"
          placeholder="Nombre"
          customClass="col-12 col-md-4"
        />

        <WafoFormInput
          type="text"
          name="last_name"
          label="Apellidos"
          placeholder="Apellidos"
          customClass="col-12 col-md-4"
        />

        <WafoFormInput
          type="email"
          name="email"
          label="Correo electrónico"
          placeholder="Correo electrónico"
          customClass="col-12 col-md-4"
          validations={{
            email: true,
          }}
        />

        <WafoFormInput
          type="text"
          name="phone_number"
          placeholder="Número de télefono"
          label="Número de télefono"
          customClass="col-12 col-md-4"
          validations={{
            regex: {
              value: /^\d{7,10}$/,
              message: 'Número de teléfono inválido.',
            }
          }}
          extraProps={{ maxLength: 10 }}
        />

        <WafoFormInput
          type="password"
          name="password"
          label="Contraseña"
          placeholder="Contraseña"
          customClass="col-12 col-md-4"
        // validations={{ required: true }}
        />

        <WafoFormInput
          type="password"
          name="password2"
          label="Confirmar contraseña"
          placeholder="Confirmar contraseña"
          customClass="col-12 col-md-4"
          validations={{
            track: 'password',
            validationFunction: {
              value: value => value.value === value.tracking,
              message: 'La contraseña no coincide.',
            },
          }}
        />

        <div className="col-12">
          <p style={{ color: '#777' }}>* Si no desea cambiar su contraseña deje los campos vacios.</p>
        </div>

      </WafoForm>

      <button type="submit" form="formUser" className={`btn btn-une ${styles['btn-submit']}`}>
        Guardar cambios
      </button>

    </div>
  );
};

FormUserProfile.propTypes = {
  currentUser: PropTypes.any,
  afterSubmit: PropTypes.func,
};

FormUserProfile.defaultProps = {
  currentUser: {},
  afterSubmit: f => f,
};

export default FormUserProfile;
