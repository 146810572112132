import React from 'react';
import PropTypes from 'prop-types';
import { WafoForm, WafoFormInput } from '@wafo/forms';
import { useParams } from 'react-router-dom';
import { connect as reduxConnect } from 'react-redux';
import { actions } from '#redux/reducers';
import { roAPI } from '#utils/axiosAPI';
import styles from './formChangePassword.module.css';

const FormChangePassword = ({ onAfterSubmit, alert }) => {
  const { email, code } = useParams();

  const handleFormSubmit = async (form, values) => {
    try {
      await roAPI.post('/auth/change/password/email', {
        email: decodeURIComponent(email),
        code: decodeURIComponent(code),
        password: values.password,
      });
      onAfterSubmit('/auth/inicio', 'redirect');
      alert({
        type: 'success',
        title: '¡Cambio de contraseña exitoso!',
        message: 'Por favor inicia sesión con tú nueva contraseña.',
      });
    } catch (error) {
      //
    }
  };

  const password = React.useRef();
  const onPasswordChange = event => (password.current = event.target.value);
  const checkPassword = value => value === password.current;

  return (
    <div data-testid="FormLogin">
      <WafoForm formId="formLogin" locale="es" onSubmit={handleFormSubmit}>
        <WafoFormInput
          type="password"
          name="password"
          label="Contraseña"
          placeholder="Contraseña"
          customClass="col-12"
          validations={{ required: true }}
          onChangeCallback={onPasswordChange}
        />

        <WafoFormInput
          type="password"
          name="password2"
          label="Confirmar contraseña"
          placeholder="Confirmar contraseña"
          customClass="col-12"
          validations={{
            required: true,
            validationFunction: {
              value: checkPassword,
              message: 'La contraseña no coincide.',
            },
          }}
        />
      </WafoForm>

      <button type="submit" form="formLogin" className={`btn btn-une ${styles['btn-submit']}`}>
        Cambiar contraseña
      </button>
    </div>
  );
};

FormChangePassword.propTypes = {
  onAfterSubmit: PropTypes.func,
  alert: PropTypes.func,
};

FormChangePassword.defaultProps = {
  onAfterSubmit: f => f,
  alert: f => f,
};

export default reduxConnect(
  () => ({}),
  dispatch => ({
    alert: alert => dispatch(actions.alert(alert)),
  }),
)(FormChangePassword);
