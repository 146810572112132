import React from 'react';
import PropTypes from 'prop-types';
import styles from './textDivider.module.css';

const TextDivider = ({ text }) => {
  return (
    <div data-testid="TextDivider" className={styles['divider']}>
      <span className={styles['line']} />
      <span className={styles['text']}>{text}</span>
      <span className={styles['line']} />
    </div>
  );
};

TextDivider.propTypes = {
  text: PropTypes.string,
};

TextDivider.defaultProps = {
  text: 'Texto de ejemplo'
};

export default TextDivider;
