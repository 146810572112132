import React from 'react';
import TabsCard from '#components/UI/tabsCard/tabsCard';
import { Route, Link, Redirect } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import TableIcons from '#components/tables/configurations/tableIcons';
import FormIcon from '#components/forms/configurations/formIcon';

const Configurations = () => (
  <TabsCard
    title="Configuración del sistema"
    headerButtons={[
      <Route
        exact
        key="/panel/configuracion/iconos"
        path="/panel/configuracion/iconos"
        render={() => (
          <Link to="/panel/configuracion/iconos/icono" className="btn btn-primary">
            <FontAwesomeIcon icon={faPlus} style={{ marginRight: '.5rem' }} />
            Agregar icono
          </Link>
        )}
      />,
    ]}
    tabs={[
      {
        to: '/panel/configuracion/iconos',
        exact: false,
        render: (
          <>
            <FontAwesomeIcon icon={faMapMarkerAlt} style={{ marginRight: '.5rem' }} />
            Iconos
          </>
        ),
      },
    ]}
    routes={[
      <Route exact key="/panel/configuracion/iconos" path="/panel/configuracion/iconos" component={TableIcons} />,
      <Route
        exact
        key="/panel/configuracion/iconos/icono"
        path="/panel/configuracion/iconos/icono"
        component={FormIcon}
      />,
      <Route
        exact
        key="/panel/configuracion/iconos/icono"
        path="/panel/configuracion/iconos/icono/:id"
        component={FormIcon}
      />,
      <Redirect
        key="/panel/configuracion"
        from="/panel/configuracion"
        to="/panel/configuracion/iconos"
      />,
    ]}
  />
);

export default Configurations;
