import React from 'react';
import PropTypes from 'prop-types';
import { connect as reduxConnect } from 'react-redux';
import { actions } from '#redux/reducers';
import { WafoForm, WafoFormInput, WafoFormSelect, WafoFormTextArea } from '@wafo/forms';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import SelectCoordinateMap from '#components/maps/selectCoordinateMap/selectCoordinateMap';
import useUpdateCities from '#hooks/useUpdateCities';
import ImagePicker from '#components/forms/customForms/imagePicker/imagePicker';
import { roAPI } from '#utils/axiosAPI';

const FormPoi = ({ history, match: { params }, location, cities, city, updateCities, changeCity, loading }) => {
  const [poi, setPoi] = React.useState(() => {
    const initialPOI = {
      address: '',
      latlng: '',
    };
    if (location.state && location.state.poi) {
      return {
        ...initialPOI,
        ...location.state.poi,
        latlng: `${location.state.poi.latitude},${location.state.poi.longitude}`,
      };
    }
    return initialPOI;
  });

  React.useEffect(() => {
    if (location.state && location.state.poi) {
      changeCity(location.state.poi.id_city);
    }
  }, [location.state, changeCity]);

  useUpdateCities(updateCities, loading, false);

  const handleFormsubmit = async (form, values) => {
    if (form.valid) {
      try {
        loading.set();
        const newPOI = {
          id_city: values.id_city,
          name: values.name,
          description: values.description,
          address: poi.address,
          latitude: poi.latlng.split(',')[0],
          longitude: poi.latlng.split(',')[1],
        };
        const res = params.id ? await roAPI.put(`/pois/${params.id}`, newPOI) : await roAPI.post('/pois', newPOI);
        setPoi(prevPOI => ({ ...prevPOI, ...res }));
        if (values.image) {
          if (typeof values.image !== 'string') {
            const formModel = new FormData();
            formModel.append('image', values.image);
            await roAPI.post(`/pois/${res.id_poi}/image`, formModel);
          }
        }
        history.push('/panel/pois');
        loading.stop();
      } catch (error) {
        loading.stop();
      }
    }
  };

  const handleMarkerAccept = React.useCallback(result => {
    if (result.addresses.length) {
      setPoi(prevPOI => ({
        ...prevPOI,
        address: result.addresses[0].address.freeformAddress,
        latlng: `${result.position[0].toFixed(6)},${result.position[1].toFixed(6)}`,
      }));
    } else {
      setPoi(prevPOI => ({
        ...prevPOI,
        latlng: `${result.position[0].toFixed(6)},${result.position[1].toFixed(6)}`,
      }));
    }
  }, []);

  return (
    <div className="row">
      <div className="col-12">
        <button type="button" className={`btn btn-link back`} onClick={() => history.goBack()}>
          <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: '.5rem' }} />
          <span>Regresar</span>
        </button>
      </div>
      <div className="col-12">
        <WafoForm key={params.id} formId="formPoi" locale="es" onSubmit={handleFormsubmit} values={poi}>
          <div className="col-12 col-md-6">
            <div className="row">
              <WafoFormSelect
                name="id_city"
                customClass="col-12"
                label="Ciudad"
                defaultValue="Selecciona ciudad"
                options={cities.map(x => ({
                  value: x.id_city,
                  display: x.name,
                }))}
                validations={{ required: true }}
                onChangeCallback={({ target: { value } }) => changeCity(value)}
              />
              <WafoFormInput
                name="name"
                label="Nombre"
                placeholder="Nombre"
                customClass="col-12"
                validations={{
                  required: true,
                  //regex: {
                  //  value: /^[a-zA-Z0-9._ ]+$/,
                  //  message: 'Solo caracteres válidos',
                  //},
                  maxLength: 254,
                }}
              />
              <WafoFormTextArea
                name="description"
                label="Descripción"
                placeholder="Descripción del lugar, señas particulares, etc."
                customClass="col-12"
                validations={{
                  required: true,
                  //regex: {
                  //  value: /^[a-zA-Z0-9._ ]+$/,
                  //  message: 'Solo caracteres válidos',
                  //},
                  maxLength: 254,
                }}
                extraProps={{
                  rows: 4,
                }}
              />
              <ImagePicker
                name="image"
                label="Imagen de POI"
                customClass="col-6"
                serverBasePath={process.env.REACT_APP_IMG_POIS}
                handleChange
              />
            </div>
          </div>

          <div className="col-12 col-md-6">
            <div className="row">
              <div className="col-12">
                <label className="label">Ubicación</label>
                <SelectCoordinateMap
                  key={city}
                  showButtons={false}
                  onAccept={handleMarkerAccept}
                  cities={cities}
                  city={city}
                  initialPos={poi.latlng ? [poi.latlng.split(',')[0], poi.latlng.split(',')[1]] : null}
                  poi
                  showShadow={false}
                />
              </div>
              <WafoFormInput
                name="address"
                label="Dirección"
                placeholder="Calle, colonia, C.P."
                customClass="col-12"
                validations={{ required: false }}
                extraProps={{
                  disabled: true,
                  style: { display: 'none' },
                }}
              >
                <input className="form-control" placeholder="Calle, colonia, C.P." value={poi.address} disabled />
              </WafoFormInput>
              <WafoFormInput
                name="latlng"
                label="Latitud y Longitud"
                placeholder="0, 0"
                customClass="col-12"
                validations={{
                  required: {
                    value: true,
                    message: 'Campo requerido. Marca la ubicación en el mapa.',
                  },
                }}
                extraProps={{
                  disabled: true,
                  style: { display: 'none' },
                }}
              >
                <input className="form-control" placeholder="0.0, 0.0" value={poi.latlng} disabled />
              </WafoFormInput>
            </div>
          </div>
        </WafoForm>
        <button type="submit" form="formPoi" className={`btn btn-une btn-submit btn-submit-center`}>
          Guardar cambios
        </button>
      </div>
    </div>
  );
};

FormPoi.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func,
    push: PropTypes.func,
  }),
  match: PropTypes.shape({
    params: PropTypes.any,
  }),
  location: PropTypes.shape({
    state: PropTypes.any,
  }),
  cities: PropTypes.array,
  updateCities: PropTypes.func,
  city: PropTypes.string,
  changeCity: PropTypes.func,
  loading: PropTypes.shape({
    set: PropTypes.func,
    stop: PropTypes.func,
  }),
};

FormPoi.defaultProps = {
  history: {
    goBack: f => f,
    push: f => f,
  },
  match: {
    params: {},
  },
  location: {
    state: {},
  },
  cities: [],
  updateCities: f => f,
  city: '1',
  changeCity: f => f,
  loading: {
    set: f => f,
    stop: f => f,
  },
};

export default reduxConnect(
  state => ({
    cities: state.cities,
    city: state.city,
  }),
  dispatch => ({
    updateCities: cities => dispatch(actions.citiesUpdate(cities)),
    changeCity: city => dispatch(actions.changeCity(city)),
    loading: {
      set: () => dispatch(actions.loadingSet()),
      stop: () => dispatch(actions.loadingStop()),
    },
  }),
)(FormPoi);
