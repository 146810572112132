import { initializeApp } from "firebase/app";
import { getAuth, signInAnonymously } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBIHWgwhC7EyaNIatEmJnnNfQb7LNk5MKY",
  authDomain: "une-transporte-urbano.firebaseapp.com",
  databaseURL: "https://une-transporte-urbano.firebaseio.com",
  projectId: "une-transporte-urbano",
  storageBucket: "une-transporte-urbano.appspot.com",
  messagingSenderId: "303288515948",
  appId: "1:303288515948:web:4dc38032e099da462f0749",
  measurementId: "G-YZ0LLTLYB6"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export const getToken = async () => {
  try {
    let storedToken = sessionStorage.getItem("fb-token");

    if (auth.currentUser) {
      const newToken = await auth.currentUser.getIdToken(true);
      if (newToken !== storedToken) {
        sessionStorage.setItem("fb-token", newToken);
        return newToken;
      }
      return storedToken;
    }

    await signInAnonymously(auth);
    const token = await auth.currentUser.getIdToken(true);
    sessionStorage.setItem("fb-token", token);
    return token;
  } catch (error) {
    console.error("Error getting token:", error);
    throw error;
  }
};

export async function initializeFbToken() {
  try {
    const fbToken = await getToken();
    return fbToken;
  } catch (error) {
    console.error("Error initializing fb-token:", error);
    throw error;
  }
}

export const validateFirebaseToken = async (token) => {
  try {
    const user = auth.currentUser;
    if (!user) {
      return false;
    }

    const newToken = await user.getIdToken(true);

    if (newToken !== token) {
      return false;
    }

    return true;
  } catch (error) {
    console.error("Error validating Firebase token:", error);
    return false;
  }
};
