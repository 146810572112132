import jwt from 'jsonwebtoken';

export function storeToken(token) {
  try {
    sessionStorage.setItem('token', token);
  } catch (error) {
    // Alternative method to sessionStorage ?
  }
}

export function getToken(decode = false) {
  try {
    const token = sessionStorage.getItem('token');
    if (decode) {
      const decoded = jwt.decode(token);
      return decoded;
    }
    return token;
  } catch (error) {
    // Alternative method to sessionStorage ?
  }
}

export async function refreshToken() {
  try {
    const token = getToken();
    const response = await fetch(`${process.env.REACT_APP_API_RO}/auth/refresh`, {
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }),
      method: 'POST',
    });
    if (!response.ok) {
      logout();
      return null;
    }
    const newToken = await response.json();
    storeToken(newToken.token);
    return newToken.token;
  } catch (error) {
    // Alternative method to sessionStorage ?
  }
}

export function isTokenExpired(token) {
  try {
    const decoded = jwt.decode(token);
    if (decoded.exp < Date.now() / 1000) {
      return true;
    }
    return false;
  } catch (error) {
    return false;
    // Alternative method to sessionStorage ?
  }
}

export async function getPublicKey() {
  try {
    const key = localStorage.getItem('publicKey');
    if (!key) {
      const response = await fetch(`${process.env.REACT_APP_API_RO}/auth/publickey`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'X-Firebase-Token': `${sessionStorage.getItem("fb-token")}`
        }
      });
      if (!response.ok) {
        return null;
      }
      const key = await response.text();
      localStorage.setItem('publicKey', key);
      return key;
    }
    return key;
  } catch (error) {
    // Alternative method to sessionStorage ?
  }
}

export async function getVerifiedToken() {
  try {
    const token = getToken();
    const key = await getPublicKey();
    const decoded = jwt.verify(token, key);
    return { token, decoded };
  } catch (error) {
    return null;
  }
}

export function logout() {
  try {
    sessionStorage.removeItem('token');
  } catch (error) {
    // Alternative method to sessionStorage ?
  }
}
