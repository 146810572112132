import React from 'react';
import PropTypes from 'prop-types';

const StreetMapsLink = ({ customClass, text, lat, lng }) => (
  <a
    className={customClass}
    title="Street View"
    href={`http://maps.google.com/maps?q=&layer=c&cbll=${lat},${lng}`}
    target="_blank"
    rel="noopener noreferrer"
  >
    {text}
  </a>
);

StreetMapsLink.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  lat: PropTypes.number,
  lng: PropTypes.number,
};

StreetMapsLink.defaultProps = {
  text: 'Link',
  lat: 0,
  lng: 0,
};

export default StreetMapsLink;
