import React from 'react';
import PropTypes from 'prop-types';
import styles from './infoColumn.module.css';

const InfoColumn = ({ colSize, title, info }) => {
  return (
    <div className={colSize}>
      <div className={styles['infocol']}>
        <span>{title}</span>
        <span>{info}</span>
      </div>
    </div>
  );
};

InfoColumn.propTypes = {
  colSize: PropTypes.string,
  title: PropTypes.string,
  info: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.number]),
};

InfoColumn.defaultProps = {
  colSize: 'col-12 col-md-4',
  title: 'Title',
  info: 'Information',
};

export default InfoColumn;
