import React from 'react';
import PropTypes from 'prop-types';
import { isMobile, BrowserView } from 'react-device-detect';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMale, faFlag, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { faMap } from '@fortawesome/free-regular-svg-icons';
import { connect as reduxConnect } from 'react-redux';
import { actions } from '#redux/reducers';
import ModalBase from '#components/modals/modalBase/modalBase';
import AzurePlaces from '#components/forms/azurePlaces/azurePlaces';
import StreetMapsLink from '#components/random/streetMapsLink/streetMapLink';
import SecondsInText from '#components/random/secondsInText/secondsInText';
import SelectCoordinateMap from '#components/maps/selectCoordinateMap/selectCoordinateMap';
import { isIFrame } from '#utils/utils';
import { roAPI } from '#utils/axiosAPI';
import styles from './crearViaje.module.css';

const stopUpIcon = require('#assets/images/pines/icon_trip_up.png');
const stopDownIcon = require('#assets/images/pines/icon_trip_down.png');
const tripBusIcon = require('#assets/images/pines/icon_trip_bus.png');
const tripWalkIcon = require('#assets/images/pines/icon_trip_walk.png');

// TODO:
// Se deben sacar al nivel superior las lineas hijas,
// de lo contrario truena cuando un viaje quiera hacer uso de una

const CrearViajeModal = ({
  showModal,
  toggleModal,
  trip,
  onTrip,
  routes,
  cities,
  updateOrigin,
  updateDestination,
  updateRoutes,
  updateSelectedTrip,
  clearTrip,
}) => {
  const [tried, setTried] = React.useState(false);

  React.useEffect(() => {
    setTried(false);
  }, [trip.origin, trip.destination]);

  const handlePlaceSelected = (place, type) => {
    if (type === 'origin') {
      updateOrigin(place);
    } else {
      updateDestination(place);
    }
  };

  const createViaje = async () => {
    try {
      const resp = await roAPI.get('/ruteo', {
        params: {
          pointA: `${trip.origin.position.lat},${trip.origin.position.lon}`,
          pointB: `${trip.destination.position.lat},${trip.destination.position.lon}`,
        },
      });
      updateRoutes(resp.result);
      onTrip(resp.result);
      if (!resp.result.length) {
        setTried(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const memoizedDestination = React.useMemo(() => {
    let destination = '';
    if (trip.destination) {
      destination = trip.destination.address.freeformAddress;
      if (trip.destination.type === 'POI') {
        destination = trip.destination.poi.name;
      }
    }
    return destination;
  }, [trip.destination]);

  const memoizedTimes = React.useMemo(() => {
    const times = [];
    trip.routes.forEach(route => {
      let walking = route.inicial.distance + route.final.distance;
      let onbus = route.recorridoA.recorrido.distance;
      if (route.type === 'complex') {
        walking += route.intermedio.distance;
        onbus += route.recorridoB.recorrido.distance;
      }
      times.push(walking * 0.72 + onbus * 0.177);
    });
    return times;
  }, [trip]);

  const flatRoutes = React.useMemo(() => {
    return routes.reduce((accumulator, line) => {
      accumulator.push(line);
      if (line.route_unions) {
        line.route_unions.forEach(child => {
          accumulator.push(child);
        });
      }
      return accumulator;
    }, []);
  }, [routes]);

  return (
    <div data-testid="CrearViajeModal">
      <ModalBase
        initialMobileMax={isMobile}
        show={showModal}
        closeModal={toggleModal}
        headerStyle={{ paddingTop: '0' }}
        bodyStyle={{ backgroundColor: '#f5f5f5' }}
        cardStyle={!isMobile && isIFrame() ? { width: '75%' } : {}}
      >
        {trip.routes.length === 0 ? (
          <div type="header">
            <h5 className={styles['header-notrip-title']}>Generar una ruta de viaje</h5>
          </div>
        ) : (
          <div type="header" className={styles['header-trip']}>
            <div className={styles['header-trip-title']}>
              <button type="button" className="btn btn-link" onClick={clearTrip}>
                <FontAwesomeIcon icon={faArrowLeft} />
              </button>
              <div>
                <p>En camino a</p>
                <h5>{memoizedDestination}</h5>
              </div>
            </div>
            <div className={styles['header-trip-estimates']}>
              <div>
                <span>Hora de llegada</span>
                <span>
                  {moment()
                    .add(memoizedTimes[trip.selectedTrip], 'seconds')
                    .format('hh:mm a')}
                </span>
              </div>
              <div>
                <span>Tiempo de recorrido</span>
                <SecondsInText seconds={memoizedTimes[trip.selectedTrip]} />
              </div>
              <BrowserView>
                <button type="button" className={`btn btn-link ${styles['trip-viewmap']}`} onClick={toggleModal}>
                  <FontAwesomeIcon icon={faMap} style={{ marginRight: '.5rem' }} />
                  Ver en mapa
                </button>
              </BrowserView>
            </div>
          </div>
        )}
        {trip.routes.length === 0 ? (
          <FormViaje
            cities={cities}
            trip={trip}
            handlePlaceSelected={handlePlaceSelected}
            createViaje={createViaje}
            updateOrigin={updateOrigin}
            updateDestination={updateDestination}
            tried={tried}
          />
        ) : (
          <InfoViaje
            trip={trip}
            routes={flatRoutes}
            destination={memoizedDestination}
            times={memoizedTimes}
            updateSelected={updateSelectedTrip}
          />
        )}
      </ModalBase>
    </div>
  );
};

CrearViajeModal.propTypes = {
  showModal: PropTypes.bool,
  toggleModal: PropTypes.func,
  trip: PropTypes.shape({
    origin: PropTypes.any,
    destination: PropTypes.any,
    routes: PropTypes.array,
    selectedTrip: PropTypes.number,
  }),
  onTrip: PropTypes.func,
  routes: PropTypes.array,
  cities: PropTypes.array,
  updateOrigin: PropTypes.func,
  updateDestination: PropTypes.func,
  updateRoutes: PropTypes.func,
  updateSelectedTrip: PropTypes.func,
  clearTrip: PropTypes.func,
};

CrearViajeModal.defaultProps = {
  showModal: false,
  toggleModal: f => f,
  trip: {
    origin: null,
    destination: null,
    routes: [],
    selectedTrip: 0,
  },
  onTrip: f => f,
  routes: [],
  cities: [],
  updateOrigin: f => f,
  updateDestination: f => f,
  updateRoutes: f => f,
  updateSelectedTrip: f => f,
  clearTrip: f => f,
};

export default reduxConnect(
  state => ({
    trip: state.trip,
    routes: state.routes,
    cities: state.cities,
  }),
  dispatch => ({
    updateOrigin: origin => dispatch(actions.tripUpdateOrigin(origin)),
    updateDestination: destination => dispatch(actions.tripUpdateDestination(destination)),
    updateRoutes: routes => dispatch(actions.tripUpdateRoutes(routes)),
    updateSelectedTrip: selected => dispatch(actions.tripUpdateSelectedTrip(selected)),
    clearTrip: () => dispatch(actions.tripClearTrip()),
  }),
)(CrearViajeModal);

/** ******************************* */
/** Form viaje */
/** ******************************* */

const FormViaje = ({ cities, trip, handlePlaceSelected, createViaje, updateOrigin, updateDestination, tried }) => {
  const [showMap, setShowMap] = React.useState(null);

  const handleAccept = result => {
    if (!result.addresses.length) {
      return;
    }
    setShowMap(null);
    const location = result.addresses[0];
    location.position = {
      lat: parseFloat(location.position.split(',')[0]),
      lon: parseFloat(location.position.split(',')[1]),
    };
    if (showMap === 'origin') {
      updateOrigin(location);
    } else if (showMap === 'destination') {
      updateDestination(location);
    }
  };

  const disabled = !trip.origin || !trip.destination;

  return (
    <>
      <div className="row">
        <div className="col-12 col-md-6">
          <AzurePlaces
            icon={faMale}
            iconColor="#2F80ED"
            placeholder="Dirección de origen"
            reduxPlace={trip.origin}
            onPlaceSelected={place => handlePlaceSelected(place, 'origin')}
            onCrosshairs={() => setShowMap('origin')}
          />
        </div>
        <div className="col-12 col-md-6">
          <AzurePlaces
            icon={faFlag}
            iconColor="#66CA21"
            placeholder="Destino"
            reduxPlace={trip.destination}
            onPlaceSelected={place => handlePlaceSelected(place, 'destination')}
            onCrosshairs={() => setShowMap('destination')}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          {tried && (
            <div className="alert alert-danger" role="alert">
              <strong>¡Lo sentimos!</strong> No se pudo generar una ruta entre las ubicaciones proporcionadas.
            </div>
          )}
          <p className={styles['disclaimer']}>
            Introduce la ubicación de origen y destino. El sistema se encargará de generar posibles rutas hacia el
            destino haciendo uso del sistema de transporte urbano.
            <br />
            Las rutas muestran tiempos y distancias estimadas. Su experiencia puede variar.
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          {showMap && (
            <>
              <span className={styles['trip-map-title']}>Seleccione la ubicación en el mapa</span>
              <SelectCoordinateMap onCancel={() => setShowMap(null)} onAccept={handleAccept} cities={cities} />
            </>
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <button
            type="button"
            className={`btn btn-primary mirai-shadow ${styles['btn-create']}`}
            onClick={createViaje}
            disabled={disabled}
          >
            Crear viaje
          </button>
        </div>
      </div>
    </>
  );
};

FormViaje.propTypes = {
  cities: PropTypes.array,
  trip: PropTypes.shape({
    origin: PropTypes.any,
    destination: PropTypes.any,
    routes: PropTypes.array,
  }),
  handlePlaceSelected: PropTypes.func,
  createViaje: PropTypes.func,
  updateOrigin: PropTypes.func,
  updateDestination: PropTypes.func,
  tried: PropTypes.bool,
};

FormViaje.defaultProps = {
  cities: [],
  trip: {
    origin: null,
    destination: null,
    routes: [],
  },
  handlePlaceSelected: f => f,
  createViaje: f => f,
  updateOrigin: f => f,
  updateDestination: f => f,
  tried: false,
};

/** ******************************* */
/** Info viaje */
/** ******************************* */

const InfoViaje = ({ trip, routes, destination, times, updateSelected }) => {
  const selectedTrip = trip.routes[trip.selectedTrip];

  return (
    <>
      <div className="row">
        <div className="col-12">
          <h5>Elige tu viaje</h5>
          <ul className={styles['trip-list']}>
            {trip.routes.map((t, i) => (
              <li key={i} className={i === trip.selectedTrip ? styles['active'] : ''} onClick={() => updateSelected(i)}>
                <div className={styles['trip-number']}>{i + 1}</div>
                <span className={styles['trip-text']}>
                  <SecondsInText seconds={times[i]} />
                </span>
              </li>
            ))}
          </ul>
        </div>
      </div>
      {/* <div className="row">
      <div className="col-12">
        <Divider />
      </div>
    </div> */}
      <div className="row">
        <div className="col-12">
          <h5>Indicaciones</h5>
          <ul className={styles['trip-directions']}>
            <li>
              <img src={stopUpIcon} alt="Parada" />
              <div className={styles['trip-directions-text']}>
                <span className={styles['trip-directions-text-label']}>Ir a parada</span>
                <span className={styles['trip-directions-text-content']}>
                  Ve a{' '}
                  <StreetMapsLink
                    text={`${selectedTrip.recorridoA.subida.name || selectedTrip.recorridoA.subida.ubicacion || ''} [${
                      selectedTrip.recorridoA.subida.codigo
                    }]`}
                    lat={selectedTrip.recorridoA.subida.latitude}
                    lng={selectedTrip.recorridoA.subida.longitude}
                  />
                </span>
              </div>
            </li>
            <div className={styles['trip-directions-line']} style={{ backgroundColor: '#0CAFB7' }} />
            <li>
              <img src={tripBusIcon} alt="Unidad" />
              <div className={styles['trip-directions-text']}>
                <span className={styles['trip-directions-text-label']}>Abordar unidad</span>
                <span className={styles['trip-directions-text-content']}>
                  Abordar {routes.find(x => x.group_id === selectedTrip.recorridoA.subida.linea).name}
                </span>
              </div>
            </li>
            <div className={styles['trip-directions-line']} style={{ backgroundColor: '#FF821B' }} />
            <li>
              <img src={stopDownIcon} alt="Unidad" />
              <div className={styles['trip-directions-text']}>
                <span className={styles['trip-directions-text-label']}>Bajar en parada</span>
                <span className={styles['trip-directions-text-content']}>
                  Bajar en{' '}
                  <StreetMapsLink
                    text={`${selectedTrip.recorridoA.bajada.name || selectedTrip.recorridoA.bajada.ubicacion || ''} [${
                      selectedTrip.recorridoA.bajada.codigo
                    }]`}
                    lat={selectedTrip.recorridoA.bajada.latitude}
                    lng={selectedTrip.recorridoA.bajada.longitude}
                  />
                </span>
              </div>
            </li>
            <div className={styles['trip-directions-line']} style={{ backgroundColor: '#E94D19' }} />
            {selectedTrip.type === 'complex' && (
              <>
                <li>
                  <img src={stopUpIcon} alt="Parada" />
                  <div className={styles['trip-directions-text']}>
                    <span className={styles['trip-directions-text-label']}>Ir a parada</span>
                    <span className={styles['trip-directions-text-content']}>
                      Ve a{' '}
                      <StreetMapsLink
                        text={`${selectedTrip.recorridoB.subida.name ||
                          selectedTrip.recorridoB.subida.ubicacion ||
                          ''} [${selectedTrip.recorridoB.subida.codigo}]`}
                        lat={selectedTrip.recorridoB.subida.latitude}
                        lng={selectedTrip.recorridoB.subida.longitude}
                      />
                    </span>
                  </div>
                </li>
                <div className={styles['trip-directions-line']} style={{ backgroundColor: '#0CAFB7' }} />
                <li>
                  <img src={tripBusIcon} alt="Unidad" />
                  <div className={styles['trip-directions-text']}>
                    <span className={styles['trip-directions-text-label']}>Abordar unidad</span>
                    <span className={styles['trip-directions-text-content']}>
                      Abordar {routes.find(x => x.group_id === selectedTrip.recorridoB.subida.linea).name}
                    </span>
                  </div>
                </li>
                <div className={styles['trip-directions-line']} style={{ backgroundColor: '#FF821B' }} />
                <li>
                  <img src={stopDownIcon} alt="Unidad" />
                  <div className={styles['trip-directions-text']}>
                    <span className={styles['trip-directions-text-label']}>Bajar en parada</span>
                    <span className={styles['trip-directions-text-content']}>
                      Bajar en{' '}
                      <StreetMapsLink
                        text={`${selectedTrip.recorridoB.bajada.name ||
                          selectedTrip.recorridoB.bajada.ubicacion ||
                          ''} [${selectedTrip.recorridoB.bajada.codigo}]`}
                        lat={selectedTrip.recorridoB.bajada.latitude}
                        lng={selectedTrip.recorridoB.bajada.longitude}
                      />
                    </span>
                  </div>
                </li>
                <div className={styles['trip-directions-line']} style={{ backgroundColor: '#E94D19' }} />
              </>
            )}
            <li>
              <img src={tripWalkIcon} alt="Caminar" />
              <div className={styles['trip-directions-text']}>
                <span className={styles['trip-directions-text-label']}>Ir a destino</span>
                <span className={styles['trip-directions-text-content']}>Dirigete a {destination}</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

InfoViaje.propTypes = {
  trip: PropTypes.shape({
    origin: PropTypes.any,
    destination: PropTypes.any,
    routes: PropTypes.array,
    selectedTrip: PropTypes.number,
  }),
  routes: PropTypes.array,
  destination: PropTypes.string,
  times: PropTypes.array,
  updateSelected: PropTypes.func,
};

InfoViaje.defaultProps = {
  trip: {
    origin: null,
    destination: null,
    routes: [],
    selectedTrip: 0,
  },
  routes: [],
  destination: '',
  times: [],
  updateSelected: f => f,
};
