import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faThLarge,
  faBell,
  faRoute,
  faCity,
  faBus,
  faMapMarkedAlt,
  faUserFriends,
  faCog,
} from '@fortawesome/free-solid-svg-icons';
import styles from './panelSidebar.module.css';
import { faIdCard } from '@fortawesome/free-regular-svg-icons';
import { getToken } from '#utils/authService';

const PanelSidebar = ({ toggled }) => {
  const token = React.useMemo(() => {
    const token = getToken(true);
    return token;
  }, []);

  return (
    <nav data-testid="PanelSidebar" className={`${styles['sidebar']} ${toggled && styles['collapsed']}`}>
      <ul className={styles['links']}>
        <li>
          <NavLink to="/panel/monitor" className={styles['link']} activeClassName={styles['active']}>
            <FontAwesomeIcon icon={faThLarge} />
            <span>Inicio</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/panel/notificaciones" className={styles['link']} activeClassName={styles['active']}>
            <FontAwesomeIcon icon={faBell} />
            <span>Notificaciones</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/panel/ciudades" className={styles['link']} activeClassName={styles['active']}>
            <FontAwesomeIcon icon={faCity} />
            <span>Ciudades</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/panel/lineas" className={styles['link']} activeClassName={styles['active']}>
            <FontAwesomeIcon icon={faRoute} />
            <span>Líneas</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/panel/paradas" className={styles['link']} activeClassName={styles['active']}>
            <FontAwesomeIcon icon={faBus} />
            <span>Paradas de autobús</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/panel/autobuses" className={styles['link']} activeClassName={styles['active']}>
            <FontAwesomeIcon icon={faBus} />
            <span>Autobuses</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/panel/pois" className={styles['link']} activeClassName={styles['active']}>
            <FontAwesomeIcon icon={faMapMarkedAlt} />
            <span>Puntos de interés</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/panel/credencializacion" className={styles['link']} activeClassName={styles['active']}>
            <FontAwesomeIcon icon={faIdCard} />
            <span>Credencialización</span>
          </NavLink>
        </li>
        {[1].includes(token.type) && (
          <li>
            <NavLink to="/panel/users" className={styles['link']} activeClassName={styles['active']}>
              <FontAwesomeIcon icon={faUserFriends} />
              <span>Usuarios</span>
            </NavLink>
          </li>
        )}
        {[1].includes(token.type) && (
          <li>
            <NavLink to="/panel/configuracion" className={styles['link']} activeClassName={styles['active']}>
              <FontAwesomeIcon icon={faCog} />
              <span>Configuración</span>
            </NavLink>
          </li>
        )}
      </ul>
    </nav>
  );
};

PanelSidebar.propTypes = {
  toggled: PropTypes.bool,
};

PanelSidebar.defaultProps = {
  toggled: false,
};

export default PanelSidebar;
