import React from 'react';
import PropTypes from 'prop-types';
import { SketchPicker } from 'react-color';
import useClickOutside from '#hooks/useClickOutside';
import styles from './colorPicker.module.css';

const ColorPicker = ({ customClass, name, label, value, handleInputChange, valid, touched, errors }) => {
  const [show, setShow] = React.useState(false);
  const toggleShow = () => setShow(prevState => !prevState);

  const clickRef = useClickOutside(show, toggleShow);

  const handleColorChange = color => {
    handleInputChange({
      target: {
        name,
        value: color.hex,
      },
      type: 'change',
    });
  };

  return (
    <div ref={clickRef} className={`wafoformcolorpicker form-group wafo-input ${styles['wrapper']} ${customClass}`}>
      {label && <label htmlFor={name}>{label}</label>}
      <div className="row">
        <div className="col-8">
          <input
            type="text"
            className={`form-control ${styles['input']}`}
            placeholder="#FFFFFF"
            value={value}
            onChange={f => f}
            onClick={toggleShow}
            ignoreinput="true"
          />
        </div>
        <div className="col-4">
          <div className={styles['color-wrapper']}>
            <div className={styles['color']} style={{ backgroundColor: value }} onClick={toggleShow} />
          </div>
        </div>
      </div>
      {show && (
        <div className={styles['picker']}>
          <SketchPicker color={value} onChangeComplete={handleColorChange} />
        </div>
      )}
      {!valid && touched && (
        <ul className="errors">
          {errors.map(error => (
            <li key={error.error}>{error.message}</li>
          ))}
        </ul>
      )}
    </div>
  );
};

ColorPicker.propTypes = {
  customClass: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.any,
  handleInputChange: PropTypes.func,
  valid: PropTypes.bool,
  touched: PropTypes.bool,
  errors: PropTypes.arrayOf(PropTypes.any),
  validations: PropTypes.object,
};

ColorPicker.defaultProps = {
  customClass: '',
  label: undefined,
  value: '',
  handleInputChange: f => f,
  valid: false,
  touched: false,
  errors: [],
  validations: {},
};

export default ColorPicker;
