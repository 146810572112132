import React from 'react';
import AlertList from '#components/alerts/alertList/alertList';

const containerFixed = {
  position: 'fixed',
  left: 0,
  right: 0,
  zIndex: 100000,
};

function AlertsHOC(WrappedComponent) {
  return function component(props) {
    return (
      <>
        <div className="container" style={containerFixed}>
          <div className="row">
            <div className="col-12">
              <AlertList />
            </div>
          </div>
        </div>
        <WrappedComponent {...props} />
      </>
    );
  }
}

export default AlertsHOC;