import React from 'react';
import PropTypes from 'prop-types';
import of from 'await-of';
import { WafoForm, WafoFormInput } from '@wafo/forms';
import { roAPI } from '#utils/axiosAPI';
import { storeToken } from '#utils/authService';
import styles from './formLogin.module.css';

const FormLogin = ({ onAfterSubmit }) => {
  const handleFormSubmit = async (form, values) => {
    if (form.valid) {
      const [res, error] = await of(
        roAPI.post('/auth/login', {
          email: values.email,
          password: values.password,
        }),
      );
      if (error && error.response && error.response.data.type === 'Auth/UserNotActivated') {
        onAfterSubmit('/auth/activate', 'redirect');
      } else if (!error) {
        storeToken(res.token);
        onAfterSubmit(res.token);
      }
    }
  };

  return (
    <div data-testid="FormLogin">
      <WafoForm formId="formLogin" locale="es" onSubmit={handleFormSubmit}>
        <WafoFormInput
          type="email"
          name="email"
          label="Correo electrónico"
          placeholder="Correo electrónico"
          customClass="col-12"
          validations={{
            required: true,
            email: true,
          }}
        />

        <WafoFormInput
          type="password"
          name="password"
          label="Contraseña"
          placeholder="Contraseña"
          customClass="col-12"
          validations={{ required: true }}
        />
      </WafoForm>

      <button type="submit" form="formLogin" className={`btn btn-une ${styles['btn-submit']}`}>
        Iniciar sesión
      </button>
    </div>
  );
};

FormLogin.propTypes = {
  onAfterSubmit: PropTypes.func,
};

FormLogin.defaultProps = {
  onAfterSubmit: f => f,
};

export default FormLogin;
