import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { BrowserView, MobileView } from 'react-device-detect';
import { isIFrame } from '#utils/utils';
import MapSidebar from '#components/navbars/mapSidebar/mapSidebar';
import LayoutOnWidth from '#components/HOCs/layoutOnWidth/layoutOnWidth';
import NotificationList from './notificationsList/notificationList';
import styles from './notifications.module.css';

const Notifications = () => {
  const [showSidebar, setShowSidebar] = React.useState(false);
  const toggleSidebar = () => setShowSidebar(prevState => !prevState);

  return (<>
    <BrowserView>
      <div className={`card mirai-shadow ${styles['notification-card']} ${isIFrame() && styles['iframe']}`}>
        <MapSidebar show showLogo forceMinWidth />
        <div className={`card-body ${styles['card-body']}`}>
          <div className={styles['header']}>
            <h2>Notificaciones</h2>
          </div>
          <NotificationList />
        </div>
      </div>
    </BrowserView>
    <MobileView>
      <div className={`card ${styles['notification-mobile-card']}`}>
        <div className={styles['left']}>
          <MapSidebar show={showSidebar} close={toggleSidebar} />
        </div>
        <div className={`card-body ${styles['card-mobile-body']}`}>
          <div className={styles['header-mobile']}>
            <button type="button" className={`btn btn-light`} disabled={showSidebar} onClick={toggleSidebar}>
              <FontAwesomeIcon icon={faBars} />
            </button>
            <h2 className={styles['title-mobile']}>Notificaciones</h2>
          </div>
          <NotificationList />
        </div>
      </div>
    </MobileView>
  </>);
};

Notifications.propTypes = {};

Notifications.defaultProps = {};

const notificationesLayout = LayoutOnWidth(Notifications, {
  browser: {
    backgroundStyle: {
      backgroundColor: '#f5f5f5',
      height: '100vh',
    },
    containerStyle: {
      height: '100%',
      alignItems: 'center',
    },
    rowStyle: {
      height: '100%',
      alignItems: 'center',
    },
    rowClass: 'row justify-content-center',
    colClass: 'col-12'
  },
})

export default notificationesLayout;