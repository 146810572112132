import React from 'react';
import PropTypes from 'prop-types';
import styles from './booleanButtons.module.css';

const BooleanButtons = ({
  trueText,
  falseText,
  invert,
  customClass,
  name,
  label,
  value,
  handleInputChange,
  valid,
  touched,
  errors,
}) => {
  const rValue = invert ? !value : value;

  const handleToggle = v => {
    handleInputChange({
      target: {
        name,
        value: invert ? !v : v,
      },
      type: 'change',
    });
  };

  return (
    <div className={`wafoformautocomplete form-group wafo-input ${customClass}`}>
      {label && <label htmlFor={name}>{label}</label>}
      <div className={`btn-group ${styles['buttons']}`} role="group" aria-label="Basic example">
        <button
          type="button"
          className={`btn btn-primary ${rValue && styles['not']}`}
          onClick={() => handleToggle(false)}
        >
          {falseText}
        </button>
        <button
          type="button"
          className={`btn btn-primary ${!rValue && styles['not']}`}
          onClick={() => handleToggle(true)}
        >
          {trueText}
        </button>
      </div>
      {!valid && touched && (
        <ul className="errors">
          {errors.map(error => (
            <li key={error.error}>{error.message}</li>
          ))}
        </ul>
      )}
    </div>
  );
};

BooleanButtons.propTypes = {
  trueText: PropTypes.string,
  falseText: PropTypes.string,
  invert: PropTypes.bool,
  customClass: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.any,
  handleInputChange: PropTypes.func,
  valid: PropTypes.bool,
  touched: PropTypes.bool,
  errors: PropTypes.arrayOf(PropTypes.any),
  validations: PropTypes.object,
};

BooleanButtons.defaultProps = {
  trueText: 'True',
  falseText: 'False',
  invert: false,
  customClass: '',
  label: undefined,
  value: false,
  handleInputChange: f => f,
  valid: false,
  touched: false,
  errors: [],
  validations: {},
};

export default BooleanButtons;
