import React from 'react';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { cardinalFromDegrees, isIFrame } from '#utils/utils';
import ModalBase from '#components/modals/modalBase/modalBase';
import FechaEnTexto from '#components/random/fechaEnTexto/fechaEnTexto';
import styles from './infoBus.module.css';

const profile = require('#assets/images/choferes/placeholder.jpg');

const InfoBusModal = ({ showModal, toggleModal, bus, routeTwoWays, routeConfigs }) => {
  const configurations = React.useMemo(() => {
    if (bus.configurations && bus.configurations.length) {
      return bus.configurations.sort((a, b) => a.order - b.order);
    } else if (bus.on_child_route) {
      return routeConfigs.child;
    }
    return routeConfigs.parent;
  }, [routeConfigs, bus]);

  return (
    <div data-testid="InfoBusModal">
      <ModalBase show={showModal} closeModal={toggleModal} cardStyle={isMobile ? {} : { width: isIFrame() ? '40%' : '30%' }}>
        <div className="row">
          <div className="col-12">
            <p className={styles['header']}>
              <FontAwesomeIcon icon={faInfoCircle} />
              <span>
                Unidad {bus.code} {routeTwoWays && <>[{bus.route_direction === 1 ? 'NORTE' : 'SUR'}]</>}
              </span>
              {configurations.length > 0 && (
                <span style={{ marginLeft: '1rem' }}>
                  [
                  {configurations.map((config, i) => (
                    <img
                      key={i}
                      src={`${process.env.REACT_APP_IMG_BUSICON}/${config.image_icon}`}
                      alt={config.name}
                      className={styles['configuration']}
                    />
                  ))}
                  ]
                </span>
              )}
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-md-12">
            <div className={`item-desc ${styles['chofer']}`}>
              <img src={profile} alt="Chofer" />
              <span className="subtitle">Chofer</span>
              <span className="value">{bus.driver_name || 'Nombre no disponible'}</span>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-md-4">
            <div className="item-desc">
              <span className="subtitle">Actualización</span>
              <span className="value">
                <span>
                  Hace <FechaEnTexto fecha={bus.utc_datetime} />
                </span>
              </span>
            </div>
          </div>

          <div className="col-12 col-md-4">
            <div className="item-desc">
              <span className="subtitle">Velocidad</span>
              <span className="value">{bus.speed} km/h</span>
            </div>
          </div>

          <div className="col-12 col-md-4">
            <div className="item-desc">
              <span className="subtitle">Dirección</span>
              <span className="value">{cardinalFromDegrees(bus.cape)}</span>
            </div>
          </div>
        </div>
      </ModalBase>
    </div>
  );
};

InfoBusModal.propTypes = {
  showModal: PropTypes.bool,
  toggleModal: PropTypes.func,
  bus: PropTypes.any,
  routeTwoWays: PropTypes.bool,
  routeConfigs: PropTypes.shape({
    parent: PropTypes.any,
    child: PropTypes.any,
  }),
};

InfoBusModal.defaultProps = {
  showModal: false,
  toggleModal: f => f,
  bus: {},
  routeTwoWays: false,
  routeConfigs: {
    parent: [],
    child: [],
  },
};

export default InfoBusModal;
