import React from 'react';
import PropTypes from 'prop-types';
import of from 'await-of';
import { connect as reduxConnect } from 'react-redux';
import { DataTable } from '@wafo/table';
import { actions } from '#redux/reducers';
import moment from 'moment';
import 'moment/locale/es';
import { roAPI } from '#utils/axiosAPI';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import confirmModal from '#components/modals/confirm/confirm';

const TableNotifications = ({ loading, alert, cities }) => {
  const [rawRows, setRawRows] = React.useState([]);
  const [totalRows, setTotalRows] = React.useState(0);

  const getData = React.useCallback(
    async event => {
      const { size, page, search } = event;
      loading.set();
      const [resp, err] = await of(
        roAPI.get('/notifications', {
          params: {
            limit: size,
            page,
            ...(search && { search }),
          },
        }),
      );
      if (!err) {
        setRawRows(resp.rows);
        setTotalRows(parseInt(resp.count, 10));
      }
      loading.stop();
    },
    [loading],
  );

  const cancelNotification = React.useCallback(
    notification => {
      async function doCancel() {
        try {
          loading.set();
          await roAPI.delete(`/notifications/${notification.id_notification}`);
          alert({
            type: 'success',
            title: 'Notificación cancelada',
            message: 'La notificación fue cancelada con exito y no será enviada',
          });
          loading.stop();
        } catch (error) {
          console.error(error);
          loading.stop();
        }
      }
      if (!notification.is_sent) {
        confirmModal({
          message: (
            <p style={{ textAlign: 'center', fontSize: '1.2em', marginBottom: '2rem' }}>
              <span>
                Al cancelar la notificación, la misma no será enviada al llegar la hora programada y será borrada del
                sistema.
              </span>
              <br />
              <span>¿Desea continuar?</span>
            </p>
          ),
          buttons: [
            {
              label: 'Cancelar',
              class: 'btn-secondary',
            },
            {
              label: 'Aceptar',
              class: 'btn-une',
              onClick: doCancel,
            },
          ],
        });
      }
    },
    [alert, loading],
  );

  const rows = React.useMemo(() => {
    return rawRows.map(row => ({
      id: row.id_notification,
      title: row.title,
      city: row.topic,
      level: { topic: row.topic, metadata: row.metadata },
      created: row.created_at,
      options: row,
    }));
  }, [rawRows]);

  const columnsConfig = React.useMemo(
    () => ({
      id: val => <span>#{val}</span>,
      title: {
        style: { fontSize: '0.85em', width: '35%' },
      },
      city: val => {
        const city = cities.find(c => c.id_city === val.split('_')[1]);
        return <span>{city ? city.name : 'Ciudad'}</span>;
      },
      level: val => {
        const topic = val.metadata ? val.metadata.topics[0] : val.topic;
        switch (topic.split('_')[2]) {
          case 'general':
            return 'Aviso general';
          case 'importante':
            return 'Aviso importante';
          case 'prueba':
            return 'Prueba';
          case 'encuesta': {
            const type = topic.split('_')[3];
            if (type === 'registrados') {
              return 'Encuesta registrados';
            }
            return 'Encuesta general';
          }
          default:
            return 'N/A';
        }
      },
      created: val => <span>{moment(val).format('DD/MMM/YYYY HH:mm a')}</span>,
      options: val => (
        <>
          <Link
            className="btn btn-sm btn-secondary"
            to={{
              pathname: `/panel/notificaciones/notificacion/${val.id_notification}`,
              state: { notification: val },
            }}
            style={{ marginRight: '.25rem' }}
          >
            <FontAwesomeIcon icon={faList} style={{ marginRight: '.5rem' }} />
            <span>Detalles</span>
          </Link>
          <button
            type="button"
            className="btn btn-sm btn-danger"
            onClick={() => cancelNotification(val)}
            disabled={val.is_sent}
          >
            <FontAwesomeIcon icon={faTrashAlt} />
          </button>
        </>
      ),
    }),
    [cities, cancelNotification],
  );

  return (
    <div data-testid="TableNotificaciones">
      <DataTable
        locale="es"
        columns={['ID', 'Título', 'Ciudad', 'Tipo', 'Creada', 'Opciones']}
        rows={rows}
        totalRows={totalRows}
        onPagination={getData}
        columnsConfig={columnsConfig}
        tableClass="table table-striped table-sm"
        tableWrapperClass="table-une"
        noRowsMessage="Sin notificaciones"
      />
    </div>
  );
};

TableNotifications.propTypes = {
  loading: PropTypes.shape({
    set: PropTypes.func,
    stop: PropTypes.func,
  }),
  alert: PropTypes.func,
  cities: PropTypes.array,
};

TableNotifications.defaultProps = {
  loading: {
    set: f => f,
    stop: f => f,
  },
  alert: f => f,
  cities: [],
};

export default reduxConnect(
  () => ({}),
  dispatch => ({
    loading: {
      set: () => dispatch(actions.loadingSet()),
      stop: () => dispatch(actions.loadingStop()),
    },
    alert: alert => dispatch(actions.alert(alert)),
  }),
)(TableNotifications);
