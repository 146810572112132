import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRandom, faTimes, faCaretUp, faCaretDown, faEraser } from '@fortawesome/free-solid-svg-icons';
import { WafoForm, WafoFormSelect, WafoFormAutocomplete } from '@wafo/forms';
import of from 'await-of';
import { DataTable } from '@wafo/table';
import moment from 'moment';
import 'moment/locale/es';
import { roAPI } from '#utils/axiosAPI';
import { connect as reduxConnect } from 'react-redux';
import { actions } from '#redux/reducers';
import confirmModal from '#components/modals/confirm/confirm';
import styles from './busses.module.css';
import useUpdateCities from '#hooks/useUpdateCities';

const TableBussesAsign = ({ loading, cities, updateCities }) => {
  const [key, setKey] = React.useState(Math.random());
  const [busses, setBusses] = React.useState([]);
  // const [cities, setCities] = React.useState([]);
  const [city, setCity] = React.useState('');
  const [lines, setLines] = React.useState([]);
  const [rawAssigns, setRawassigns] = React.useState([]);
  const [totalAssigns, setTotalassigns] = React.useState(0);
  const [assignsByLine, setAssignsbyline] = React.useState([]);
  const [dropdowns, setDropdowns] = React.useState({
    busses: false,
    lines: true,
  });

  // TODO:
  // Revisar si al borrar la forma se borra también state:city
  // Y buscar una forma inteligente de actualizar la tabla, pero borrando la forma.

  const getAssigns = React.useCallback(
    async event => {
      try {
        const { size, page, search } = event;
        loading.set();
        if (city) {
          const resp = await roAPI.get('/vehicles/assigns', {
            params: {
              limit: size,
              page,
              ...(search && { search }),
              ...(lines.length && { id_route: lines.map(x => x.id_route).join(',') }),
            },
          });
          setRawassigns(resp.rows);
          setTotalassigns(parseInt(resp.count, 10));
        }
        loading.stop();
      } catch (error) {
        loading.stop();
      }
    },
    [loading, lines, city],
  );

  const getAssignsByLine = React.useCallback(async () => {
    loading.set();
    const [res, err] = await of(
      roAPI.get(`/vehicles/assigns/routes`, {
        params: {
          id_city: city,
        },
      }),
    );
    if (!err) {
      setAssignsbyline(res);
    }
    loading.stop();
  }, [loading, city]);

  useUpdateCities(updateCities, loading, false);

  React.useEffect(() => {
    async function getBusses() {
      try {
        loading.set();
        const busses = await roAPI.get('/vehicles/all');
        if (Array.isArray(busses)) {
          setBusses(busses);
        }
        loading.stop();
      } catch (error) {
        loading.stop();
      }
    }
    /* async function getCities() {
      loading.set();
      const [res] = await of(
        roAPI.get('/cities', {
          params: { disabled: false },
        }),
      );
      setCities(res.rows);
      loading.stop();
    } */
    getBusses();
    // getCities();
  }, [loading]);

  React.useEffect(() => {
    async function getLines() {
      loading.set();
      const [res, err] = await of(roAPI.get(`/routes/city/${city}`));
      if (!err) {
        const flat = res.reduce((accumulator, line) => {
          accumulator.push(line);
          line.route_unions.forEach(child => {
            accumulator.push(child);
          });
          return accumulator;
        }, []);
        setLines(flat);
      }
      loading.stop();
    }
    if (city) {
      getLines();
    }
  }, [city, loading]);

  React.useEffect(() => {
    if (city) {
      getAssignsByLine();
    }
  }, [city, getAssignsByLine]);

  const handleFormSubmit = async (form, values) => {
    async function doSubmit() {
      loading.set();
      const [, err] = await of(
        roAPI.post('/vehicles/assigns', {
          id_route: values.line,
          vehicle_code: values.bus.code,
        }),
      );
      if (!err) {
        setKey(Math.random());
        // Reload cochino.
        const elem = document.getElementById('wafotable-reload');
        elem.click();
        getAssignsByLine();
      }
      loading.stop();
    }
    if (form.valid) {
      confirmModal({
        message: (
          <p style={{ textAlign: 'center', fontSize: '1.2em', marginBottom: '2rem' }}>
            El autobús se mostrará en la línea asignada y dejara de mostrarse en su línea original.
            <br />
            ¿Desea continuar?
          </p>
        ),
        buttons: [
          {
            label: 'Cancelar',
            class: 'btn-secondary',
          },
          {
            label: 'Aceptar',
            class: 'btn-une',
            onClick: doSubmit,
          },
        ],
      });
    }
  };

  const removeAssign = React.useCallback(
    code => {
      async function doRemove() {
        const [, err] = await of(roAPI.delete(`/vehicles/assigns/${code}`));
        if (!err) {
          // Reload cochino.
          const elem = document.getElementById('wafotable-reload');
          elem.click();
          getAssignsByLine();
        }
      }
      confirmModal({
        message: (
          <p style={{ textAlign: 'center', fontSize: '1.2em', marginBottom: '2rem' }}>
            El autobús ya no se mostrará en la línea asignada.
            <br />
            ¿Desea continuar?
          </p>
        ),
        buttons: [
          {
            label: 'Cancelar',
            class: 'btn-secondary',
          },
          {
            label: 'Aceptar',
            class: 'btn-une',
            onClick: doRemove,
          },
        ],
      });
    },
    [getAssignsByLine],
  );

  const removeAllAssign = React.useCallback(() => {
    async function doRemove() {
      try {
        loading.set();
        // Deleting
        await roAPI.delete('/vehicles/assigns/purge');
        // Reload cochino.
        const elem = document.getElementById('wafotable-reload');
        elem.click();
        getAssignsByLine();
        loading.stop();
      } catch (error) {
        loading.stop();
      }
    }
    confirmModal({
      message: (
        <p style={{ textAlign: 'center', fontSize: '1.2em', marginBottom: '2rem' }}>
          Todos los autobuses ya no se mostraran en las líneas asignadas.
          <br />
          ¿Desea continuar?
        </p>
      ),
      buttons: [
        {
          label: 'Cancelar',
          class: 'btn-secondary',
        },
        {
          label: 'Aceptar',
          class: 'btn-une',
          onClick: doRemove,
        },
      ],
    });
  }, [loading, getAssignsByLine]);

  const assigned = React.useMemo(() => {
    if (busses.length && rawAssigns.length && lines.length && city) {
      const linesObj = {};
      lines.forEach(x => {
        linesObj[x.id_route] = x;
      });

      return rawAssigns.map(assign => ({
        code: assign.vehicle_code,
        old_line: linesObj[assign.old_id_route],
        line: linesObj[assign.id_route],
        date: assign.created_at,
        options: assign.vehicle_code,
      }));
    }
    return [];
  }, [busses, city, lines, rawAssigns]);

  const columnsConfig = React.useMemo(
    () => ({
      old_line: val => <span>{val ? val.name : 'Línea no encontrada'}</span>,
      line: val => <span>{val ? val.name : 'Línea no encontrada'}</span>,
      date: val => <span>{moment(val).format('DD-MM-YYYY HH:mm')}</span>,
      options: {
        render: function col(val) {
          return (
            <>
              <button type="button" className="btn btn-sm btn-danger" onClick={() => removeAssign(val)}>
                <FontAwesomeIcon icon={faTimes} style={{ marginRight: '.5rem' }} />
                Cancelar
              </button>
            </>
          );
        },
        style: {
          whiteSpace: 'nowrap',
        },
      },
    }),
    [removeAssign],
  );

  return (
    <React.Fragment>
      <div className="row">
        <div className="col-12">
          <h5>Asignación de autobuses</h5>
        </div>
      </div>

      <WafoForm key={key} formId="formBusses" locale="es" onSubmit={handleFormSubmit}>
        <WafoFormSelect
          name="city"
          customClass="col-3"
          label="Ciudad"
          defaultValue="Seleccione ciudad"
          options={cities.map(x => ({ value: x.id_city, display: x.name }))}
          onChangeCallback={({ target: { value } }) => setCity(value)}
          validations={{ required: true }}
        />

        <WafoFormSelect
          name="line"
          customClass="col-3"
          label="Línea"
          defaultValue="Seleccione línea"
          options={lines
            .filter(x => x.id_route && x.id_route !== -1)
            .map(x => ({
              value: x.id_route,
              display: x.name,
            }))}
          validations={{ required: true }}
        />

        <WafoFormAutocomplete
          name="bus"
          customClass="col-3"
          label="Autobús"
          placeholder="Seleccione un autobús"
          items={busses}
          filterItems={(items, query) => items.filter(item => item.code.toLowerCase().indexOf(query.toLowerCase()) !== -1)}
          renderInput={item => `${item.code}`}
          renderItem={item => <p className="autocomplete-item">Unidad: {item.code}</p>}
          validations={{ required: true }}
          extraProps={{
            autoComplete: 'off',
          }}
          handleChange
        />

        <div className={`col-3 ${styles['buttons']}`}>
          <button type="submit" className="btn btn-une">
            <FontAwesomeIcon icon={faRandom} style={{ marginRight: '.5rem' }} />
            <span>Asignar</span>
          </button>
        </div>
      </WafoForm>

      <div className="row">
        <div className="col-12">
          <p className={styles['desc']}>
            La asignación manual de autobuses a línea permite configurar temporalmente la unidad en cualquier línea de su elección.
            Seleccione la unidad y línea deseadas para realizar la asignación.
          </p>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className={styles['sub-separador']} onClick={() => setDropdowns(prev => ({ ...prev, lines: !prev.lines }))}>
            <h5>Número de autobuses asignados por línea</h5>
            <FontAwesomeIcon icon={dropdowns.lines ? faCaretUp : faCaretDown} />
          </div>
        </div>
      </div>

      <div className={`row justify-content-center ${dropdowns.lines ? styles['toggled'] : ''}`}>
        <div className="col-12" style={{ marginBottom: '2rem' }}>
          <div className="row">
            {assignsByLine.map(x => (
              <div key={x.id_route} className="col-6">
                <div className={styles['line']}>
                  <span>
                    <strong>{x.name}</strong>
                  </span>
                  <span>{x.total} Unidades</span>
                </div>
              </div>
            ))}
            {!city && assignsByLine.length < 1 && (
              <div className="col-12">
                <p>Seleccione una ciudad para continuar</p>
              </div>
            )}
            {city && assignsByLine.length < 1 && (
              <div className="col-12">
                <p>Sin asignaciones en la ciudad</p>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className={styles['sub-separador']} onClick={() => setDropdowns(prev => ({ ...prev, busses: !prev.busses }))}>
            <h5>Autobuses asignados a otras líneas</h5>
            <FontAwesomeIcon icon={dropdowns.busses ? faCaretUp : faCaretDown} />
          </div>
        </div>
      </div>

      <div className={`row ${dropdowns.busses ? styles['toggled'] : ''}`}>
        <div className="col-12">
          <DataTable
            locale="es"
            columns={['Código unidad', 'Línea original', 'Línea asignada', 'Fecha de asignación', 'Opciones']}
            rows={assigned}
            totalRows={totalAssigns}
            onPagination={getAssigns}
            columnsConfig={columnsConfig}
            tableClass="table table-striped table-sm"
            tableWrapperClass="table-une"
            noRowsMessage={city ? 'Sin asignaciones en la ciudad' : 'Seleccione una ciudad para continuar'}
            controls={
              <React.Fragment>
                <button type="button" className="btn btn-primary" disabled={totalAssigns === 0} onClick={removeAllAssign}>
                  <FontAwesomeIcon icon={faEraser} />
                </button>
              </React.Fragment>
            }
          />
        </div>
      </div>
    </React.Fragment>
  );
};

TableBussesAsign.propTypes = {
  cities: PropTypes.array,
  loading: PropTypes.shape({
    set: PropTypes.func,
    stop: PropTypes.func,
  }),
  updateCities: PropTypes.func,
};

TableBussesAsign.defaultProps = {
  cities: [],
  loading: {
    set: f => f,
    stop: f => f,
  },
  updateCities: f => f,
};

export default reduxConnect(
  state => ({
    cities: state.cities,
  }),
  dispatch => ({
    updateCities: cities => dispatch(actions.citiesUpdate(cities)),
    loading: {
      set: () => dispatch(actions.loadingSet()),
      stop: () => dispatch(actions.loadingStop()),
    },
  }),
)(TableBussesAsign);
