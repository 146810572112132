import React from 'react';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import styles from './modalBase.module.css';

const ModalBase = ({ initialMobileMax, show, closeModal, closeOnBackdrop, cardStyle, headerStyle, bodyStyle, children }) => {
  const [mobileMax, setMobileMax] = React.useState(initialMobileMax);
  const toggleMobileMax = () => setMobileMax(prevState => !prevState);

  if (!show) { return null; }

  const childrenArray = React.Children.toArray(children);
  const bodyChildren = childrenArray.filter(x => x.props.type !== 'header');
  const headerChildren = childrenArray.filter(x => x.props.type === 'header');

  return (
    <div data-testid="ModalBase" className={isMobile ? styles['modal-mobile'] : styles['modal']}>
      <div className={`${isMobile ? styles['card-mobile'] : styles['card']} ${mobileMax && styles['card-mobile-max']} card animated fadeInUp faster`} style={cardStyle}>

        <div className={`card-header ${styles['header']}`} style={headerStyle}>
          <button type="button" className={styles['expand-button']} onClick={toggleMobileMax} >
            <span className={styles['expand-button-line']} />
          </button>
          {headerChildren}
        </div>

        <div className={`card-body ${isMobile ? styles['card-body-mobile'] : styles['card-body']}`} style={bodyStyle}>
          {bodyChildren}
        </div>

      </div>
      <div className={styles['backdrop']} onClick={closeOnBackdrop ? closeModal : f => f} />
    </div>
  );
};

ModalBase.propTypes = {
  initialMobileMax: PropTypes.bool,
  show: PropTypes.bool,
  closeModal: PropTypes.func,
  closeOnBackdrop: PropTypes.bool,
  cardStyle: PropTypes.any,
  headerStyle: PropTypes.any,
  bodyStyle: PropTypes.any,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
};

ModalBase.defaultProps = {
  initialMobileMax: false,
  show: false,
  closeModal: f => f,
  closeOnBackdrop: true,
  cardStyle: {},
  headerStyle: {},
  bodyStyle: {},
  children: [],
};

export default ModalBase;