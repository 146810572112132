import React from 'react';
import { render, unmountComponentAtNode } from 'react-dom';
import PropTypes from 'prop-types';
import styles from './confirm.module.css';

const ModalConfirm = ({ title, message, buttons }) => {
  const closeModal = () => removeElement();

  const handleButtonClick = button => {
    if (button.onClick) {
      button.onClick();
    }
    closeModal();
  };

  return (
    <div className={styles['modal']}>
      <div className={`card animated fadeInUp faster ${styles['card']}`}>
        <div className="card-body">
          {typeof title === 'string' ? <div className={styles['title']}>{title}</div> : <>{title}</>}
          {typeof message === 'string' ? <div className={styles['message']}>{message}</div> : <>{message}</>}
          <div className={styles['buttons']}>
            {buttons.map((button, i) => (
              <button
                key={i}
                className={`btn ${button.class || 'btn-primary'} ${styles['button']}`}
                onClick={() => handleButtonClick(button)}
              >
                {button.label}
              </button>
            ))}
          </div>
        </div>
      </div>
      <div className={styles['backdrop']} onClick={closeModal} />
    </div>
  );
};

ModalConfirm.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  buttons: PropTypes.array,
};

ModalConfirm.defaultProps = {
  title: 'Advertencia',
  message: '¿Seguro de continuar?',
  buttons: [
    {
      label: 'Cancel',
      class: 'btn-danger',
      onClick: () => null,
    },
    {
      label: 'Confirm',
      onClick: () => null,
    },
  ],
};

function removeElement(id = 'wafo-confirm-alert') {
  const target = document.getElementById(id);
  unmountComponentAtNode(target);
  target.parentNode.removeChild(target);
}

export default function confirmModal(properties, id = 'wafo-confirm-alert') {
  let divTarget = document.getElementById(id);
  if (!divTarget) {
    divTarget = document.createElement('div');
    divTarget.setAttribute('id', id);
    document.body.appendChild(divTarget);
  }
  render(<ModalConfirm {...properties} />, divTarget);
}
