import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
// Redux
import { Provider as StoreProvider } from 'react-redux';
import store from '#redux/store';
// Styles
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/animate.css/animate.min.css';
import '../node_modules/@wafo/forms/dist/index.css';
import '../node_modules/@wafo/table/lib/styles.css';
import './App.css';
// Components
import CitySelector from './scenes/citySelector/citySelector';
import MapView from './scenes/mapView/mapView';
import Panel from './scenes/panel/panel';
import Login from './scenes/auth/login/login';
import Profile from './scenes/auth/profile/profile';
import Notifications from './scenes/notifications/notifications';
import PrivateRoute from '#components/router/privateRoute';
// Firebase
import { initializeFbToken } from './utils/firebase';

/* if (process.env.NODE_ENV !== 'development') {
  Sentry.init({ dsn: "https://12a29ad0c3604dff862523008d61be9e@sentry.io/2128336" });
} */
Sentry.init({ dsn: 'https://12a29ad0c3604dff862523008d61be9e@sentry.io/2128336' });

// Checking if sessionStorage is available
try {
  sessionStorage.getItem('token');
} catch (error) {
  if (error.code === DOMException.SECURITY_ERR) {
    alert('AVISO: Para el funcionamiento correcto de la página se requiere acceso a Cookies y datos de sitio.');
  }
}

function App() {
  const [loading, setLoading] = useState(true); 

  useEffect(() => {
    const initFbToken = async () => {
      try {
        setLoading(true);
        await initializeFbToken();
      } catch (error) {
        console.error('Error initializing Firebase token:', error);
      } finally {
        setLoading(false);
      }
    };

    initFbToken();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div data-testid="App" className="App">
      <StoreProvider store={store}>
        <Router>
          <Switch>
            <Route exact path="/" component={CitySelector} />
            <Route exact path="/rutas" component={MapView} />
            <Route path="/auth" component={Login} />
            <Route path="/perfil" component={Profile} />
            <Route path="/notificaciones" component={Notifications} />
            <PrivateRoute path="/panel" component={Panel} allowed={[1, 3]} />
          </Switch>
        </Router>
      </StoreProvider>
    </div>
  );
}

export default App;
