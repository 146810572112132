import React from 'react';
import PropTypes from 'prop-types';
import { DataTable } from '@wafo/table';
import { connect as reduxConnect } from 'react-redux';
import { actions } from '#redux/reducers';
import { roAPI } from '#utils/axiosAPI';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faToggleOn, faToggleOff, faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import moment from 'moment';
import confirmModal from '#components/modals/confirm/confirm';
import styles from './configurations.module.css';

const TableIcons = ({ loading }) => {
  const [rows, setRows] = React.useState([]);
  const [totalRows, setTotalRows] = React.useState(0);

  const getData = React.useCallback(
    async event => {
      try {
        const { size, page, search } = event;
        loading.set();
        const resp = await roAPI.get(`/configurations/icons`, {
          params: {
            limit: size,
            page,
            ...(search && { search }),
          },
        });
        setRows(
          resp.rows.map(row => ({
            id: row.id_icon,
            status: row.disabled,
            icon: {
              icon: row.image_icon,
              color: row.primary_color,
            },
            order: row.order,
            name: row.name,
            // description: row.description,
            date: row.updated_at,
            options: row,
          })),
        );
        setTotalRows(resp.count);
        loading.stop();
      } catch (error) {
        loading.stop();
      }
    },
    [loading],
  );

  const clickReload = (id = 'wafotable-reload') => {
    const btn = document.getElementById(id);
    btn.click();
  };

  const handleDisable = React.useCallback(
    (id, val) => {
      async function disableType() {
        try {
          loading.set();
          await roAPI.put(`/configurations/icons/${id}`, {
            disabled: !val,
          });
          clickReload();
          loading.stop();
        } catch (error) {
          loading.stop();
        }
      }
      confirmModal({
        message: (
          <p style={{ textAlign: 'center', fontSize: '1.2em', marginBottom: '2rem' }}>
            {!val
              ? 'El tipo no podrá ser utilizado en el sistema de transporte.'
              : 'El tipo podrá ser utilizado en el sistema de transporte.'}
            <br />
            ¿Desea continuar?
          </p>
        ),
        buttons: [
          {
            label: 'Cancelar',
            class: 'btn-secondary',
          },
          {
            label: 'Aceptar',
            class: 'btn-une',
            onClick: disableType,
          },
        ],
      });
    },
    [loading],
  );

  const handleDelete = React.useCallback(
    id => {
      async function deleteType() {
        try {
          loading.set();
          await roAPI.delete(`/configurations/icons/${id}`);
          clickReload();
          loading.stop();
        } catch (error) {
          loading.stop();
        }
      }
      confirmModal({
        message: (
          <p style={{ textAlign: 'center', fontSize: '1.2em', marginBottom: '2rem' }}>
            Una vez eliminado, no se podran configurar unidades con este tipo.
            <br />
            ¿Desea continuar?
          </p>
        ),
        buttons: [
          {
            label: 'Cancelar',
            class: 'btn-secondary',
          },
          {
            label: 'Aceptar',
            class: 'btn-une',
            onClick: deleteType,
          },
        ],
      });
    },
    [loading],
  );

  const columnsConfig = React.useMemo(
    () => ({
      status: val => (
        <span className={`badge table-badge ${!val ? 'badge-success' : 'badge-danger'}`}>
          {!val ? 'Activo' : 'Inactivo'}
        </span>
      ),
      icon: val => (
        <div className={styles['icono-wrapper']} style={{ backgroundColor: val.color }}>
          <img className={styles['icono']} src={`${process.env.REACT_APP_IMG_BUSICON}/${val.icon}`} alt="icon" />
        </div>
      ),
      date: {
        render: function col(val) {
          return <span>{moment(val).format('DD MMMM YYYY HH:mm')}</span>;
        },
        style: { fontSize: '0.8em' },
      },
      options: val => (
        <React.Fragment>
          <button
            type="button"
            className={`btn btn-sm ${!val.disabled ? 'btn-success' : 'btn-warning'}`}
            style={{ padding: '.25rem 1rem', marginRight: '0.25rem' }}
            onClick={() => handleDisable(val.id_icon, val.disabled)}
            // disabled={val.protected}
          >
            <FontAwesomeIcon icon={!val.disabled ? faToggleOn : faToggleOff} />
          </button>
          <Link
            className="btn btn-sm btn-primary"
            to={{ pathname: `/panel/configuracion/iconos/icono/${val.id_icon}`, state: { type: val } }}
            style={{ marginRight: '0.25rem' }}
          >
            <FontAwesomeIcon icon={faEdit} style={{ marginRight: '.5rem' }} />
            <span>Editar</span>
          </Link>
          <button
            type="button"
            className="btn btn-sm btn-danger"
            onClick={() => handleDelete(val.id_icon)}
            disabled={val.protected}
          >
            <FontAwesomeIcon icon={faTrashAlt} style={{ marginRight: '.5rem' }} />
            Eliminar
          </button>
        </React.Fragment>
      ),
    }),
    [handleDisable, handleDelete],
  );

  return (
    <React.Fragment>
      <div className="row">
        <div className="col-12">
          <h5>Tipos de autobus</h5>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <DataTable
            locale="es"
            columns={['ID', 'Estado', 'Icono', 'Prioridad', 'Nombre', 'Actualizado', 'Opciones']}
            rows={rows}
            totalRows={totalRows}
            onPagination={getData}
            columnsConfig={columnsConfig}
            tableClass="table table-striped table-sm"
            tableWrapperClass="table-une"
            noRowsMessage="Sin tipos de autobus para mostrar"
          />
        </div>
      </div>
    </React.Fragment>
  );
};

TableIcons.propTypes = {
  loading: PropTypes.shape({
    set: PropTypes.func,
    stop: PropTypes.func,
  }),
};

TableIcons.defaultProps = {
  loading: {
    set: f => f,
    stop: f => f,
  },
};

export default reduxConnect(
  () => ({}),
  dispatch => ({
    loading: {
      set: () => dispatch(actions.loadingSet()),
      stop: () => dispatch(actions.loadingStop()),
    },
  }),
)(TableIcons);
