import React from 'react';
import PropTypes from 'prop-types';
import { connect as reduxConnect } from 'react-redux';
import { actions } from '#redux/reducers';
import { WafoForm, WafoFormInput, WafoFormTextArea } from '@wafo/forms';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useHistory, useParams } from 'react-router-dom';
import BooleanButtons from '#components/forms/customForms/booleanButtons/booleanButtons';
import ColorPicker from '#components/forms/customForms/colorPicker/colorPicker';
import ImagePicker from '#components/forms/customForms/imagePicker/imagePicker';
import { saveAs } from 'file-saver';
import { roAPI } from '#utils/axiosAPI';

const exampleIcon = require('#assets/images/pines/icon_bus_default.png');

const FormIcon = ({ loading, location, alert }) => {
  const [type] = React.useState(() => {
    if (location.state && location.state.type) {
      return {
        ...location.state.type,
      };
    }
    return {};
  });

  const history = useHistory();
  const { id: editing_id } = useParams();

  const downloadExample = () => {
    saveAs(exampleIcon, 'icono-ejemplo.png');
  };

  const handleFormSubmit = async (form, values) => {
    if (form.valid) {
      try {
        loading.set();
        // Creating form model
        const formModel = new FormData();
        formModel.append('name', values.name);
        formModel.append('description', values.description);
        formModel.append('primary_color', values.primary_color);
        formModel.append('disabled', !!values.disabled);
        formModel.append('order', values.order);
        if (typeof values.image_icon !== 'string' && values.image_icon) {
          formModel.append('image', values.image_icon);
        }
        // submitting
        editing_id
          ? await roAPI.put(`/configurations/icons/${editing_id}`, formModel)
          : await roAPI.post('/configurations/icons', formModel);
        history.push('/panel/configuracion/iconos');
        alert({
          type: 'success',
          title: '¡Tipo de autobus añadido!',
          message: 'Se ha añadido exitosamente el nuevo tipo de autobus.',
          timer: 3000,
        });
        loading.stop();
      } catch (error) {
        loading.stop();
        console.error(error);
      }
    }
  };

  return (
    <div className="row">
      <div className="col-12">
        <button type="button" className={`btn btn-link back`} onClick={() => history.goBack()}>
          <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: '.5rem' }} />
          <span>Regresar</span>
        </button>
      </div>

      <div className="col-12">
        <WafoForm formId="formCity" locale="es" onSubmit={handleFormSubmit} values={type}>
          <WafoFormInput
            type="text"
            name="name"
            label="Nombre"
            placeholder="Nombre"
            customClass="col-12 col-md-4"
            validations={{ required: true }}
          />

          <ColorPicker
            name="primary_color"
            label="Color de tipo"
            customClass="col-12 col-md-4"
            validations={{ required: true }}
            handleChange
          />

          <BooleanButtons
            name="disabled"
            label="Tipo activo"
            customClass="col-12 col-md-4"
            trueText="Activo"
            falseText="Inactivo"
            invert
            handleChange
          />

          <div className="col-12 col-md-4">
            <div className="row">
              <ImagePicker
                name="image_icon"
                label="Icono de tipo"
                customClass="col-12"
                serverBasePath={process.env.REACT_APP_IMG_BUSICON}
                help="El icono debe tener un tamaño de 130px x 150px (o un aspecto similar), formato PNG y de poco peso (50KB)."
                validations={{ required: true }}
                handleChange
              />
              <div className="col-12">
                <button type="button" className="btn btn-link no-padding" onClick={downloadExample}>
                  Descargar ejemplo de icono
                </button>
              </div>
            </div>
          </div>

          <WafoFormInput
            type="text"
            name="order"
            label="Prioridad"
            placeholder="0"
            customClass="col-12 col-md-4"
            validations={{ required: true }}
            extraProps={{
              min: 0,
              max: 10,
            }}
          >
            <span style={{ fontSize: '0.8em', color: '#777', marginBottom: 0 }}>
              El orden de prioridad indica que se mostrara en la aplicación y mapa. Mientras menor el número, mayor
              prioridad.
            </span>
          </WafoFormInput>

          <WafoFormTextArea
            name="description"
            label="Descripción de tipo"
            placeholder="Descripción de tipo"
            customClass="col-12 col-md-4"
            validations={{ required: true }}
          />
        </WafoForm>
        <button type="submit" form="formCity" className={`btn btn-une btn-submit btn-submit-center`}>
          Guardar cambios
        </button>
      </div>
    </div>
  );
};

FormIcon.propTypes = {
  loading: PropTypes.shape({
    set: PropTypes.func,
    stop: PropTypes.func,
  }),
  alert: PropTypes.func,
  location: PropTypes.shape({
    state: PropTypes.any,
  }),
};

FormIcon.defaultProps = {
  loading: {
    set: f => f,
    stop: f => f,
  },
  alert: f => f,
  location: {
    state: {},
  },
};

export default reduxConnect(
  () => ({}),
  dispatch => ({
    loading: {
      set: () => dispatch(actions.loadingSet()),
      stop: () => dispatch(actions.loadingStop()),
    },
    alert: alert => dispatch(actions.alert(alert)),
  }),
)(FormIcon);
