import React from 'react';
import PropTypes from 'prop-types';
import { WafoForm, WafoFormInput } from '@wafo/forms';
import { connect as reduxConnect } from 'react-redux';
import { actions } from '#redux/reducers';
import { roAPI } from '#utils/axiosAPI';
import styles from './formRequestActivate.module.css';

const FormRequestActivate = ({ onAfterSubmit, alert }) => {
  const handleFormSubmit = async (form, values) => {
    try {
      await roAPI.post('/auth/request/email', {
        email: values.email,
      });
      onAfterSubmit('/auth/inicio', 'redirect');
      alert({
        type: 'success',
        title: '¡Estas a un solo paso!',
        message:
          'Se ha enviado un nuevo código de verificación. Por favor revisa tu bandeja de entrada.',
      });
    } catch (error) {
      //
    }
  }

  return (
    <div data-testid="FormLogin">
      
      <p>Se enviará el correo de activación con las instrucciones a tu correo.</p>
      
      <WafoForm formId="formLogin" locale="es" onSubmit={handleFormSubmit}>
        <WafoFormInput
          type="email"
          name="email"
          label="Correo electrónico"
          placeholder="Correo electrónico"
          customClass="col-12"
          validations={{
            required: true,
            email: true,
          }}
        />
      </WafoForm>

      <button type="submit" form="formLogin" className={`btn btn-une ${styles['btn-submit']}`}>
        Reenviar correo de activación
      </button>

    </div>
  );
};

FormRequestActivate.propTypes = {
  onAfterSubmit: PropTypes.func,
  alert: PropTypes.func,
};

FormRequestActivate.defaultProps = {
  onAfterSubmit: f => f,
  alert: f => f,
}
export default reduxConnect(
  () => ({}),
  dispatch => ({
    alert: alert => dispatch(actions.alert(alert)),
  }),
)(FormRequestActivate);
