import React from 'react';
import PropTypes from 'prop-types';
import of from 'await-of';
import { connect as reduxConnect } from 'react-redux';
import { DataTable } from '@wafo/table';
import { actions } from '#redux/reducers';
import { roAPI } from '#utils/axiosAPI';
import moment from 'moment';
import 'moment/locale/es';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';

const TableCities = ({ loading }) => {
  const [rawRows, setRawRows] = React.useState([]);
  const [totalRows, setTotalRows] = React.useState(0);

  const getData = React.useCallback(
    async event => {
      const { size, page, search } = event;
      loading.set();
      const [resp, err] = await of(
        roAPI.get('/cities', {
          params: {
            limit: size,
            nocache: true,
            page,
            ...(search && { search }),
          },
        }),
      );
      if (!err) {
        setRawRows(resp.rows);
        setTotalRows(parseInt(resp.count, 10));
      }
      loading.stop();
    },
    [loading],
  );

  const rows = rawRows.map(row => ({
    id: row.id_city,
    status: row.disabled,
    name: row.name,
    date: row.created_at,
    options: { id: row.id_city, city: row },
  }));

  const columnsConfig = React.useMemo(
    () => ({
      status: val => (
        <span className={`badge table-badge ${!val ? 'badge-success' : 'badge-warning'}`}>
          {!val ? 'Activa' : 'Inactiva'}
        </span>
      ),
      date: val => <span>{moment(val).format('DD MMMM YYYY HH:mm')}</span>,
      options: val => (
        <>
          <Link
            className="btn btn-sm btn-primary"
            to={{ pathname: `/panel/ciudades/ciudad/${val.id}`, state: { city: val.city } }}
          >
            <FontAwesomeIcon icon={faEdit} style={{ marginRight: '.5rem' }} />
            <span>Editar</span>
          </Link>
        </>
      ),
    }),
    [],
  );

  return (
    <div>
      <DataTable
        locale="es"
        columns={['ID', 'Estado', 'Nombre', 'Última actualización', 'Opciones']}
        rows={rows}
        totalRows={totalRows}
        onPagination={getData}
        columnsConfig={columnsConfig}
        tableClass="table table-striped table-sm"
        tableWrapperClass="table-une"
        noRowsMessage="Sin notificaciones"
      />
    </div>
  );
};

TableCities.propTypes = {
  loading: PropTypes.shape({
    set: PropTypes.func,
    stop: PropTypes.func,
  }),
};

TableCities.defaultProps = {
  loading: {
    set: f => f,
    stop: f => f,
  },
};

export default reduxConnect(
  () => ({}),
  dispatch => ({
    loading: {
      set: () => dispatch(actions.loadingSet()),
      stop: () => dispatch(actions.loadingStop()),
    },
  }),
)(TableCities);
