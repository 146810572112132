import React from 'react';
import PropTypes from 'prop-types';
import { Map, TileLayer, Marker, Polygon } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { generateMarker } from '#components/maps/extras/markers/markers';
import styles from './selectBoundsMap.module.css';

const targetIcon = generateMarker({ icon: 'target' });

const SelectBoundsMap = ({ onBounds, initialNE, initialSW }) => {
  const [neLatLng, setNELatLng] = React.useState(initialNE);
  const [swLatLng, setSWLatLng] = React.useState(initialSW);

  React.useEffect(() => {
    if (neLatLng && swLatLng) {
      onBounds({ neLatLng, swLatLng });
    }
  }, [neLatLng, swLatLng, onBounds]);

  const handleClick = event => {
    if (!neLatLng) {
      setNELatLng([event.latlng.lat, event.latlng.lng]);
    } else {
      setSWLatLng([event.latlng.lat, event.latlng.lng]);
    }
  };

  const nw = neLatLng && swLatLng ? [neLatLng[0], swLatLng[1]] : [0, 0];
  const se = neLatLng && swLatLng ? [swLatLng[0], neLatLng[1]] : [0, 0];

  return (
    <div className={styles['wrapper']}>
      <div className={styles['map-wrapper']}>
        <Map
          center={[29.297255, -110.330955]}
          zoom={6}
          maxZoom={18}
          animate
          bounds={null}
          className={styles['leaflet-container']}
          onclick={handleClick}
        >
          <TileLayer
            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a>'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {neLatLng && (
            <Marker
              position={neLatLng}
              title="NE"
              icon={targetIcon}
              draggable
              onDragEnd={({ target: { _latlng } }) => setNELatLng([_latlng.lat, _latlng.lng])}
            />
          )}
          {swLatLng && (
            <Marker
              position={swLatLng}
              title="SO"
              icon={targetIcon}
              draggable
              onDragEnd={({ target: { _latlng } }) => setSWLatLng([_latlng.lat, _latlng.lng])}
            />
          )}
          {neLatLng && swLatLng && <Polygon positions={[neLatLng, nw, swLatLng, se]} fill />}
        </Map>
      </div>
    </div>
  );
};

SelectBoundsMap.propTypes = {
  onBounds: PropTypes.func,
  initialNE: PropTypes.any,
  initialSW: PropTypes.any,
};

SelectBoundsMap.defaultProps = {
  onBounds: f => f,
  initialNE: null,
  initialSW: null,
};

export default SelectBoundsMap;
