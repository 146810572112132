import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Link } from 'react-router-dom';
import TableCities from '#components/tables/tableCities/tableCities';
import FormCity from '#components/forms/formCity/formCity';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

const Cities = ({ match }) => {
  return (
    <div>
      <div className="section-header">
        <h2 className="title">Ciudades</h2>
        <Route
          exact
          path={`${match.url}`}
          render={() => (
            <Link to="/panel/ciudades/ciudad" className="btn btn-primary">
              <FontAwesomeIcon icon={faPlus} style={{ marginRight: '.5rem' }} />
              Agregar ciudad
            </Link>
          )}
        />
      </div>
      <div className="card">
        <div className="card-body">
          <Switch>
            <Route exact path={`${match.url}/`} component={TableCities} />
            <Route exact path={`${match.url}/ciudad/:id`} component={FormCity} />
            <Route exact path={`${match.url}/ciudad`} component={FormCity} />
          </Switch>
        </div>
      </div>
    </div>
  );
};

Cities.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string,
  }),
};

Cities.defaultProps = {
  match: {
    url: '',
  },
};

export default Cities;
